import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/body-component.css'
import "../../style/organization.css";
const BodyComponent = (props) => {
    return (
        <div>
            {props.SuperAdminBody}
        </div>
    );

};

export default BodyComponent;
