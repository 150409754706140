import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

export default class ReactGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getRowHeight: function (params) {
        return 30;
      },
      headerHeight: 30,
      isRowSelectable: false,
      defaultColDef: {
        flex: 1,
        //editable: true,
        // sortable: true,
        resizable: true,
        suppressMovable: true,
        // filter: true,
      },
    };
    this.gridApi = "";
    this.columnApi = "";
    this.onGridReady = this.onGridReady.bind(this);
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    if (
      this.props.onGridReady &&
      typeof this.props.onGridReady === "function"
    ) {
      this.props.onGridReady(params);
    }
  };
  render() {
    return (
      <div
        id={this.props.id}
        style={this.props.containerStyle}
        className="ag-theme-fresh"
      >
        <AgGridReact
          singleClickEdit={true}
          defaultColDef={this.state.defaultColDef}
          rowData={this.props.rowData}
          // column definitions
          columnDefs={this.props.columnDefs}
          // events
          onGridReady={this.onGridReady}
          externalProps={this.props.externalProps}
          //row height
          getRowHeight={() => 30}
          //header height
          rowSelection={this.props.rowSelection || "single"}
          onRowSelected={this.props.onRowSelect}
          suppressRowClickSelection={this.props.suppressRowClickSelection}
          headerHeight={() => 30}
          enableCellTextSelection={true}
          onCellClicked={this.props.onCellClicked}
        ></AgGridReact>
      </div>
    );
  }
}
