import { Modal } from 'react-bootstrap';
import { React, useState, useEffect } from 'react'
import { messages } from '../../utils/constants';
import axios from "axios";
import { Form, Row, Col } from 'react-bootstrap';
import { notify, isMatches, focusById, isEmail, uploadFile } from '../../utils/utility';

const AddUpdateStudent = ({ setShow, show, getStudent, studentDetails }) => {
    const invalidData = {
        name: false
    };
    const [invalid, setInvalid] = useState(invalidData);
    const [name, setName] = useState("");

    useEffect(() => {
        studentDetails.name && setName(studentDetails.name);
    }, [studentDetails])

    const validator = () => {
        let invalid = {
            name: false
        };
        var flag = false;
        /*Validations*/
        if (!isMatches(name, messages.OnlyAlphaNumeric)) {
            flag = invalid.name = true;
            focusById('name');
            notify(messages.ToastError, messages.InvalidName)
        }
        setInvalid(invalid)
        return flag ? false : true;
    }

    const submit = () => {
        let dataString = {
            name: name ? name.trim() : "",
            id: studentDetails.id || "",
            actionType: studentDetails.id ? "edit" : "add"
        }
        let url = '/defaultStudentNames/addUpdate';
        let isValid = validator();
        if (isValid) {
            axios.post(url, dataString)
                .then(function (response) {
                    // handle success
                    let data = response.data ? response.data : {};
                    if (data.errorCode) {
                        if (data.errorCode === 406) {
                            notify(messages.ToastError, messages.StudentAlreadyExist);
                        }
                    } else if (response.status === 200) {
                        if (studentDetails.id) {
                            notify(messages.ToastSuccess, "Student updated successfully.");
                        } else {
                            notify(messages.ToastSuccess, "Student added successfully.");
                        }
                        setShow(false);
                        getStudent();
                    } else {
                        notify(messages.ToastError, messages.ServerNotResponding);
                    }
                })
                .catch(function (error) {

                })
        }
    }
    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <div style={{ paddingLeft: "2rem", paddingRight: "2rem", paddingTop: "1rem", paddingBottom: "1rem" }}>
                <Modal.Header closeButton className="py-0  px-0 Heading-for-modal" >
                    {studentDetails.id ? "Edit" : "Add"} {messages.Student}
                </Modal.Header>
                <Modal.Body className="px-0 pb-0"><div>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>{messages.Name}*</Form.Label>
                                <Form.Control type="text" placeholder="" value={name} className={invalid.name ? " is-invalid" : ""} onChange={(e) => { setName(e.target.value) }} maxLength={255} />
                            </Form.Group>
                        </Col>
                    </Row>
                </div></Modal.Body>
                <Modal.Footer className="px-0">
                    <div className="submitAndCloseBtn">
                        <button className={`button-custom submitBtn`} variant="primary" onClick={submit}>
                            {messages.Save}
                        </button>
                        <button className="button-custom closeBtn" variant="primary" onClick={() => setShow(false)}>
                            {messages.cancel}
                        </button>
                    </div>
                </Modal.Footer>
            </div>
        </Modal >
    )
}
export default AddUpdateStudent;