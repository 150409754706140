import React, { useEffect } from 'react'
import AddUser from './add-user';


const Profile = () => {
    useEffect(() => {
        localStorage.removeItem("editUserId");
    }, [])
    return (
        <>
            <AddUser Profile={true} />
        </>
    )
}

export default Profile
