import { React, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { messages } from "../utils/constants";
import {
  notify,
  isMatches,
  focusById,
  isEmail,
  toDefaultDateFormat,
} from "../utils/utility";
import { Container, Form, Row, Col } from "react-bootstrap";
import "../../style/global.css";
import axios from "axios";
import { ReactTitle } from "react-meta-tags";
import moment from "moment";
import Select from "react-select";
const _ = require("lodash");

const AddOrganization = ({ data }) => {
  const history = useHistory();
  const invalidData = {
    Organization: false,
    Industry: false,
    Website: false,
    ExpiryDate: false,
    Street: false,
    ZipCode: false,
    Name: false,
    Email: false,
    ConfirmEmail: false,
    Mobile: false,
    OfficePhone: false,
    MacId: false,
  };
  const macIdInvalid = {
    MacId: false,
    MacIdMatch: "macId1",
  };
  const [invalid, setInvalid] = useState(invalidData);
  const [invalidMacId, setInvalidMacId] = useState(macIdInvalid);
  const [device, setDevice] = useState([{ type: 2, macId: "" }]);
  const [macIdMatch, setMacIdMatch] = useState(macIdInvalid);
  const [organization, setOrganization] = useState();
  const [Industry, setIndustry] = useState();
  const [Website, setWebsite] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [street, setStreet] = useState();
  const [cameraDisableForOrg, setCameraDisableForOrg] = useState(false);
  const [cameraDefaultOffForOrg, setCameraDefaultOffForOrg] = useState(false);
  const [allowScreenChange, setAllowScreenChange] = useState(false);
  const [allowMeterChange, setAllowMeterChange] = useState(false);
  const [meterDisabledForOrg, setMeterDisabledForOrg] = useState(true);
  const [meterGroupingDisabledForOrg, setMeterGroupingDisabledForOrg] = useState(true);

  // const accessToken = localStorage.getItem('accessToken') || "";
  const organizationId = localStorage.getItem("orgId") || "";

  const [action, setAction] = useState("add");
  const [zipCode, setZipCode] = useState();
  const [currentMinDate, setCurrentMinDate] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [confirmEmail, setConfirmEmail] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [officePhone, setOfficePhone] = useState();
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [country, setCountry] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});
  const [assignMeters, setAssignMeters] = useState(null);
  const [assignedMeterCategories, setAssignedMeterCategories] = useState(null);
  const [meters, setMeters] = useState([]);

  /* start update */
  const [organizationData, setOrganizationData] = useState();
  const [myOrgId, setMyOrgId] = useState();

  const [savedState, setSavedState] = useState();
  const [savedCity, setSavedCity] = useState();

  useEffect(() => {
    if (organizationId) {
      getOrganizationData();
    } else {
      getMeterList();
      getCountryList();
    }
    let formattedCurrentMinDate = toDefaultDateFormat(new Date());
    setCurrentMinDate(formattedCurrentMinDate);
  }, []);

  const handleSelectCountry = (event) => {
    setCountry({
      value: event.target.value,
      name: event.target.options[event.target.selectedIndex].text,
    });
  };
  const handleSelectState = (event) => {
    setState({
      value: event.target.value,
      name: event.target.options[event.target.selectedIndex].text,
    });
  };
  const handleSelectCity = (event) => {
    setCity({
      value: event.target.value,
      name: event.target.options[event.target.selectedIndex].text,
    });
  };

  useEffect(() => {
    setState("");
    setSavedState("");
    if (country) getStateList();
  }, [country]);
  useEffect(() => {
    setCity("");
    setSavedCity("");
    if (state) getCityList();
  }, [state]);

  const getOrganizationData = () => {
    const url = "/Organizations/getOrganizationData";
    axios
      .post(url, {
        //accessToken: accessToken,
        id: organizationId,
      })
      .then((res) => {
        if (res) {
          const organizationData = res.data;
          if (organizationData) {
            setAction("edit");
            setOrganization(organizationData.name);
            setIndustry(organizationData.industry);
            setWebsite(organizationData.website);
            setExpiryDate(moment(organizationData?.expiryDate).format('YYYY-MM-DD'));
            setStreet(organizationData.street);
            setCountry(organizationData.country);
            setState(organizationData.state);
            setCity(organizationData.city);
            setZipCode(organizationData.zipCode);
            setName(organizationData.contactName);
            setEmail(organizationData.contactEmail);
            setConfirmEmail(organizationData.contactEmail);
            setMobileNumber(organizationData.contactMobile);
            setOfficePhone(organizationData.contactOffice);
            setMyOrgId(organizationData.id);
            let devices = organizationData.devices.length
              ? organizationData.devices
              : [{ type: 2, macId: "" }];
            setDevice(devices);
            getCountryList(organizationData.country);
            setSavedState(organizationData.state);
            setSavedCity(organizationData.city);
            getMeterList(organizationData.assignedMeters);
            setCameraDisableForOrg(organizationData.cameraDisableForOrg);
            setCameraDefaultOffForOrg(organizationData.cameraDefaultOffForOrg);
            setAllowScreenChange(organizationData?.allowSkinChangeInTraining);
            setAllowMeterChange(organizationData?.allowMeterChangeInTraining);
            setMeterDisabledForOrg(organizationData?.meterDisabledForOrg);
            setMeterGroupingDisabledForOrg(organizationData?.meterGroupingDisabledForOrg);
            setAssignedMeterCategories(messages.category.slice(1).filter(cat => organizationData?.assignedMeterCategories?.some((catVal) => catVal == cat.value)))
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCountryList = (savedCountry) => {
    axios
      .post("/getCountryList", {
        //accessToken: accessToken
      })
      .then((response) => {
        // handle success
        let data = response.data ? response.data : {};
        if (data.errorCode) {
          notify(messages.ToastError, "server issue");
        } else {
          let countryCodeList = [];
          for (let i in data) {
            let code = {
              id: i,
              label: data[i].phone_code,
              value: data[i].phone_code,
            };
            countryCodeList.push(code);
            data[i].label = data[i].name;
            data[i].value = data[i].uniqueId;
          }
          setCountryCodeList(countryCodeList);
          setCountryList(data);
          /*country*/
          if (savedCountry) {
            let selectCountry = {};
            selectCountry = _.find(
              data,
              function (inst) {
                return inst["label"] === savedCountry;
              }
            );
            setCountry(selectCountry);
          } else {
            setCountry({ value: data[0].value, name: data[0].name });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getStateList = () => {
    axios
      .post("/getStateList", { countryUniqueId: country.value })
      .then(function (response) {
        // handle success
        let data = response.data ? response.data : {};
        if (data.errorCode) {
          notify(messages.ToastError, "server issue");
        } else {
          for (let i in data) {
            data[i].label = data[i].name;
            data[i].value = data[i].uniqueId;
          }
          setStateList(data);
          /*state*/
          if (savedState) {
            let selectState = {};
            selectState = _.find(
              data,
              function (inst) {
                return inst["label"] === savedState;
              }
            );
            setState(selectState);
          } else {
            setState({ value: data[0].value, name: data[0].name });
          }
        }
      })
      .catch(function (error) { });
  };
  const getCityList = () => {
    axios
      .post("/getCityList", { stateUniqueId: state.value })
      .then(function (response) {
        // handle success
        let data = response.data ? response.data : {};
        if (data.errorCode) {
          notify(messages.ToastError, "server issue");
        } else {
          for (let i in data) {
            data[i].label = data[i].name;
            data[i].value = data[i].uniqueId;
            data[i].latitude = data[i].latitude;
            data[i].longitude = data[i].longitude;
          }
          setCityList(data);
          /*city*/
          if (savedCity) {
            let selectCity = {};
            selectCity = _.find(
              data,
              function (inst) {
                return inst["label"] === savedCity;
              }
            );
            setCity(selectCity);
          } else {
            setCity({ value: data[0].value, name: data[0].name });
          }
        }
      })
      .catch(function (error) { });
  };

  function submitHandler(e) {
    e.preventDefault();
    const countryS = country.name;
    const stateS = state.name;
    const cityS = city.name;
    let d =
      device.filter((item) => {
        return item.macId !== "";
      }) || [];
    const orgData = {
      //accessToken,
      actionType: action,
      organization,
      Industry,
      Website,
      expiryDate,
      street,
      countryS,
      stateS,
      cityS,
      zipCode,
      name,
      email,
      mobileNumber,
      officePhone,
      device: d,
      id: organizationId,
      //   cameraFlag,
      meterDisabledForOrg,
      meterGroupingDisabledForOrg,
      cameraDisableForOrg,
      assignedMeterCategories: assignedMeterCategories?.map?.(i => i.value) || [],
      assignedMeters: assignMeters?.map?.(i => ({ id: i.value })) || [],
      cameraDefaultOffForOrg,
      allowScreenChange,
      allowMeterChange
    };
    let isValid = validator();
    if (isValid) {
      axios
        .post("/Organizations/addUpdateOrganizations", orgData)
        .then((res) => {
          if (res.data.errorCode) {
            if (res.data.errorCode === 407) {
              notify(
                messages.ToastError,
                messages.organizationEmailAlreadyExist
              );
            } else if (res.data.errorCode === 406) {
              notify(
                messages.ToastError,
                messages.organizationNameAlreadyExist
              );
            } else if (res.data.errorCode === 422) {
              if (res.data.name === "USER_SAME_ORG")
                notify(
                  messages.ToastError,
                  messages.orgContactSameOrgExist
                );
              else if (res.data.name === "USER_OTHER_ORG")
                notify(
                  messages.ToastError,
                  messages.orgContactOtherOrgExist
                );
            }
          } else {
            if (action === "add") {
              notify(messages.ToastSuccess, messages.organizationCreated);
            } else {
              notify(messages.ToastSuccess, messages.organizationUpdated);
            }
            history.push("/Home/Organizations");
          }
        })
        .catch((error) => {
          notify(messages.ToastError, messages.contactError);
        });
    }
  }

  const validator = () => {
    let invalid = {
      Organization: false,
      Industry: false,
      Website: false,
      ExpiryDate: false,
      Street: false,
      ZipCode: false,
      Name: false,
      Email: false,
      ConfirmEmail: false,
      Mobile: false,
      OfficePhone: false,
      MacId: false,
    };
    var flag = false;
    let d =
      device.filter((item) => {
        return item.macId != "";
      }) || [];
    const unique = new Set();
    const showError = d.some(
      (element) => unique.size === unique.add(element.macId).size
    );
    /*Validations*/
    const _expiryDate = new Date(expiryDate);
    if (!isMatches(organization, messages.RegexAlphaNumericSpecialChar)) {
      flag = invalid.Organization = true;
      focusById("Organization");
      notify(messages.ToastError, messages.InvalidOrgName);
    } else if (Industry && !isMatches(Industry, messages.OnlyAlphaNumeric)) {
      flag = invalid.Industry = true;
      focusById("Industry");
      notify(messages.ToastError, messages.InvalidIndustryName);
    } else if (Website && !isMatches(Website, messages.RegexWebsite)) {
      flag = invalid.Website = true;
      focusById("Website");
      notify(messages.ToastError, messages.InvalidWebsiteName);
    } else if (
      expiryDate &&
      _expiryDate.getTime() < new Date(currentMinDate).getTime()
    ) {
      flag = invalid.ExpiryDate = true;
      focusById("ExpiryDate");
      notify(messages.ToastError, messages.InvalidExpiryDate);
    } else if (!isMatches(street, messages.RegexAlphaNumericSpecialChar)) {
      flag = invalid.Street = true;
      focusById("Street");
      notify(messages.ToastError, messages.InvalidStreetName);
    } else if (!isMatches(zipCode, messages.RegexAlphaNumericSpecialChar)) {
      flag = invalid.ZipCode = true;
      focusById("ZipCode");
      notify(messages.ToastError, messages.InvalidZipCode);
    } else if (!isMatches(name, messages.OnlyAlphaNumeric)) {
      flag = invalid.Name = true;
      focusById("Name");
      notify(messages.ToastError, messages.InvalidName);
    } else if (!isEmail(email)) {
      flag = invalid.Email = true;
      focusById("Email");
      notify(messages.ToastError, messages.InvalidEmail);
    } else if (confirmEmail !== email) {
      flag = invalid.ConfirmEmail = true;
      focusById("ConfirmEmail");
      notify(messages.ToastError, messages.InvalidConfirmEmail);
    } else if (!isMatches(mobileNumber, messages.RegexAllowForPhoneNumber)) {
      flag = invalid.Mobile = true;
      focusById("Mobile");
      notify(messages.ToastError, messages.InvalidMobileNumber);
    } else if (!isMatches(officePhone, messages.RegexAllowForPhoneNumber)) {
      flag = invalid.OfficePhone = true;
      focusById("OfficePhone");
      notify(messages.ToastError, messages.InvalidPhoneNumber);
    } else if (showError) {
      flag = invalid.MacId = true;
      notify(messages.ToastError, messages.InvalidMacDulicateId);
    }
    setInvalid(invalid);
    return flag ? false : true;
  };

  const macIdValidator = () => {
    let invalid = {
      MacId: false,
      MacIdMatch: "macId" + device.length,
    };
    var flag = false;
    if (!device[device.length - 1].macId.trim().length) {
      flag = invalid.MacId = true;
      focusById("macId" + device.length);
      notify(messages.ToastError, messages.InvalidMacId);
    }
    setInvalidMacId(invalid);
    return flag ? false : true;
  };

  /* start devices */

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...device];
    list[index][name] = value;
    setDevice(list);
  };
  const handleRemoveClick = (event, index) => {
    event.preventDefault();
    const list = [...device];
    list.splice(index, 1);
    setDevice(list);
  };
  const handleAddClick = (e) => {
    e.preventDefault();
    let isValid = macIdValidator();
    // setMacIdMatch('macId'+device.length)
    if (isValid) {
      if (device[device.length - 1].macId.trim().length) {
        setDevice([...device, { type: 2, macId: "" }]);
      }
    }
  };
  const handleSelectType = (select, i) => {
    const list = [...device];
    if (+select.target.value === 1) {
      if (!list.filter((inst) => +inst.type === 1).length) {
        list[i].type = +select.target.value || "";
        setDevice(list);
      } else {
        notify(messages.ToastError, messages.DuplicateTablet);
      }
    } else {
      list[i].type = +select.target.value || "";
      setDevice(list);
    }
  };
  const handleAssignMeterCategories = (val) => {
    setAssignedMeterCategories(val);
  };
  const handleAssignMeter = (val) => {
    setAssignMeters(val);
  };

  const Devices = device.map((x, i) => {
    //let deviceType = {};
    // if (x.type) {
    //     let selectType = {};
    //     selectType = _.find(messages.typeList, function (inst) {
    //         return inst["value"] === x.type
    //     }.bind(this));
    //     deviceType = selectType;
    // }
    let compareMacId = "macId" + (+i + 1);
    return (
      <div key={"device-" + i}>
        <Row>
          <Col lg={4} md={4} sm={4} xs={4}>
            <Form.Group className="mb-3" controlId="formGroupSkin">
              <Form.Label>{messages.Type + "*"}</Form.Label>
              <Form.Select
                className="SelectBox"
                id={"type-" + i}
                aria-label="Default select example"
                value={x.type}
                onChange={(e) => handleSelectType(e, i)}
              >
                {messages.typeList.map((e) => {
                  return (
                    <option key={e.id} value={e.value}>
                      {e.label}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <Form.Group className="mb-3" controlId={"macId" + i}>
              <Form.Label>{messages.MacId + "*"}</Form.Label>
              <Form.Control
                type="text"
                className={
                  invalidMacId.MacId && invalidMacId.MacIdMatch === compareMacId
                    ? " is-invalid"
                    : ""
                }
                name="macId"
                placeholder=""
                value={x.macId}
                onChange={(e) => handleInputChange(e, i)}
                maxLength={50}
              />
            </Form.Group>
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <div className="addDeleteBtn">
              {device.length !== 1 && (
                <i
                  onClick={(e) => handleRemoveClick(e, i)}
                  style={{ color: "#FF7538" }}
                  className="fas fa-trash-alt mx-4 pointer"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Delete Device"
                ></i>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  });
  const getMeterList = (savedAssignMeters) => {
    const url = "/Meters/getMeterList";
    axios
      .post(url, {
        skip: 0,
        limit: 0,
        searchContent: "",
        fields: [
          "id",
          "name",
          "sensors"
        ],
        showTotalCount: true,
      })
      .then((res) => {
        if (res.data) {
          let { items = [] } = res.data;
          let meterList = items?.map?.((item, index) => ({
            label: item.name,
            value: item.id,
            id: index
          })) || [];
          setMeters(meterList);
          if (savedAssignMeters) {
            let selectedAssignMeters = meterList.filter((inst) =>
              savedAssignMeters?.some?.((val) => val.id === inst?.value)
            ) || [];
            setAssignMeters(selectedAssignMeters);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  let disable =
    !organization ||
    !street ||
    !country ||
    !state ||
    !city ||
    !zipCode ||
    !name ||
    !email ||
    !mobileNumber ||
    !officePhone ||
    !device[device.length - 1].macId.trim() ||
    !device[device.length - 1].type ||
    !expiryDate;
  /* end devices */
  return (
    <Container>
      <ReactTitle
        title={
          action === "add" ? "Home-Add-Organization" : "Home-Edit-Organization"
        }
      />
      <Row className="border-bottom">
        <div className="add-update-heading">
          {action === "add"
            ? messages.AddOrganization
            : messages.EditOrganization}
        </div>
      </Row>

      <Row className="add-update-right-wrapper">
        <div className="mt-3 form-inputs-div">
          <Row>
            <h5>{messages.BasicInfo}</h5>
            <Col lg={4}>
              <Form.Group className="mb-3" controlId="Organization">
                <Form.Label>{messages.Organization}*</Form.Label>
                <Form.Control
                  className={invalid.Organization ? " is-invalid" : ""}
                  type="text"
                  placeholder=""
                  value={organization}
                  onChange={(e) => setOrganization(e.target.value)}
                  minLength={3}
                  maxLength={255}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3" controlId="Industry">
                <Form.Label>{messages.Industry}</Form.Label>
                <Form.Control
                  className={invalid.Industry ? " is-invalid" : ""}
                  type="text"
                  placeholder=""
                  value={Industry}
                  onChange={(e) => setIndustry(e.target.value)}
                  maxLength={255}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3" controlId="Website">
                <Form.Label>{messages.Website}</Form.Label>
                <Form.Control
                  type="text"
                  className={invalid.Website ? " is-invalid" : ""}
                  placeholder=""
                  value={Website}
                  onChange={(e) => setWebsite(e.target.value)}
                  maxLength={255}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3" controlId="ExpiryDate">
                <Form.Label>{messages.ExpiryDate}*</Form.Label>
                <Form.Control
                  type="date"
                  className={invalid.ExpiryDate ? " is-invalid" : ""}
                  value={expiryDate}
                  onChange={(e) => setExpiryDate(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="mt-5 form-inputs-div">
          <Row>
            <h5>{messages.Address}</h5>
            <Col lg={4}>
              <Form.Group className="mb-3" controlId="Street">
                <Form.Label>{messages.Street}*</Form.Label>
                <Form.Control
                  type="text"
                  className={invalid.street ? " is-invalid" : ""}
                  placeholder=""
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  maxLength={500}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3" controlId="country">
                <Form.Label>{messages.Country}*</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={country ? country.value : ""}
                  onChange={handleSelectCountry}
                >
                  {countryList.map((e, key) => {
                    return (
                      <option key={"Country-" + key} value={e.value}>
                        {e.label}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3" controlId="State">
                <Form.Label>{messages.State}*</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={state ? state.value : ""}
                  onChange={handleSelectState}
                >
                  {stateList.length ? (
                    stateList.map((e, key) => {
                      return (
                        <option key={"State-" + key} value={e.value}>
                          {e.label}
                        </option>
                      );
                    })
                  ) : (
                    <option value="" disabled>
                      State are not availble
                    </option>
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3" controlId="City">
                <Form.Label>{messages.City}*</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={city ? city.value : ""}
                  onChange={handleSelectCity}
                >
                  {cityList.length ? (
                    cityList.map((e, key) => {
                      return (
                        <option key={"City-" + key} value={e.value}>
                          {e.label}
                        </option>
                      );
                    })
                  ) : (
                    <option value="" disabled>
                      City are not availble
                    </option>
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3" controlId="ZipCode">
                <Form.Label>{messages.ZipCode}*</Form.Label>
                <Form.Control
                  placeholder=""
                  type="text"
                  className={invalid.ZipCode ? " is-invalid" : ""}
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  minLength={5}
                  maxLength={30}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="mt-5 form-inputs-div">
          <Row>
            <h5>{messages.Contact}</h5>
            <Col lg={4}>
              <Form.Group className="mb-3" controlId="Name">
                <Form.Label>{messages.Name}*</Form.Label>
                <Form.Control
                  type="text"
                  className={invalid.Name ? " is-invalid" : ""}
                  placeholder=""
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  maxLength={255}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3" controlId="Email">
                <Form.Label>{messages.EmailTitleCase}*</Form.Label>
                <Form.Control
                  type="email"
                  className={invalid.Email ? " is-invalid" : ""}
                  placeholder=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  maxLength={255}
                // disabled={action === "edit" ? true : false}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3" controlId="ConfirmEmail">
                <Form.Label>{messages.ConfirmEmail}*</Form.Label>
                <Form.Control
                  type="ConfirmEmail"
                  className={invalid.ConfirmEmail ? " is-invalid" : ""}
                  placeholder=""
                  value={confirmEmail}
                  onChange={(e) => setConfirmEmail(e.target.value)}
                  maxLength={255}
                // disabled={action === "edit" ? true : false}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3" controlId="Mobile">
                <Form.Label>{messages.MobileNumber}*</Form.Label>
                <Form.Control
                  type="text"
                  className={invalid.Mobile ? " is-invalid" : ""}
                  placeholder=""
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  minLength={8}
                  maxLength={15}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3" controlId="OfficePhone">
                <Form.Label>{messages.OfficePhone}*</Form.Label>
                <Form.Control
                  className={invalid.OfficePhone ? " is-invalid" : ""}
                  type="text"
                  placeholder=""
                  value={officePhone}
                  onChange={(e) => setOfficePhone(e.target.value)}
                  minLength={8}
                  maxLength={25}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Camera </Form.Label>
                <div>
                  <div>
                    <label className="combineSwitch">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setCameraDisableForOrg(!cameraDisableForOrg);
                          setCameraDefaultOffForOrg(!cameraDisableForOrg);
                        }}
                        checked={cameraDisableForOrg}
                      />
                      <span className="slider round"></span>
                    </label>
                    <Form.Label className="px-2">Disable for Organization </Form.Label>
                  </div>
                  <div>
                    <label className="combineSwitch">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setCameraDefaultOffForOrg(!cameraDefaultOffForOrg)
                        }
                        checked={cameraDefaultOffForOrg}
                        disabled={cameraDisableForOrg}
                      />
                      <span className="slider round"></span>
                    </label>
                    <Form.Label className={`px-2 ${cameraDisableForOrg ? 'text-muted' : ''}`}>
                      Default off for Organization
                    </Form.Label>
                  </div>
                </div>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Training</Form.Label>
                <div>
                  <div>
                    <label className="combineSwitch">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          setAllowScreenChange(prev => !prev);
                        }}
                        checked={allowScreenChange}
                      />
                      <span className="slider round"></span>
                    </label>
                    <Form.Label className="px-2">Allow skin change </Form.Label>
                  </div>
                  <div>
                    <label className="combineSwitch">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setAllowMeterChange(prev => !prev)
                        }
                        checked={allowMeterChange}
                      />
                      <span className="slider round"></span>
                    </label>
                    <Form.Label className={`px-2`}>
                      Allow meter change
                    </Form.Label>
                  </div>
                </div>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3">
                <Form.Label>Meter</Form.Label>
                <div>

                  <div>
                    <label className="combineSwitch">
                      <input
                        type="checkbox"
                        onChange={() => setMeterDisabledForOrg(!meterDisabledForOrg)}
                        checked={meterDisabledForOrg}
                      />
                      <span className="slider round"></span>
                    </label>
                    <Form.Label className={`px-2`}>
                      Disable Meter Creation
                    </Form.Label>
                  </div>

                  <div>
                    <label className="combineSwitch">
                      <input
                        type="checkbox"
                        onChange={() => setMeterGroupingDisabledForOrg(!meterGroupingDisabledForOrg)}
                        checked={meterGroupingDisabledForOrg}
                      />
                      <span className="slider round"></span>
                    </label>
                    <Form.Label className={`px-2`}>
                      Disable Device Grouping
                    </Form.Label>
                  </div>
                </div>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3" controlId="assign-meter-Categories">
                <Form.Label>{messages.AssignMeterCategory}</Form.Label>
                <Select
                  className="SelectBox"
                  placeholder="Select Category"
                  options={messages.category.slice(1)}
                  value={assignedMeterCategories}
                  isMulti
                  isSearchable
                  onChange={handleAssignMeterCategories}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="mb-3" controlId="assign-meters">
                <Form.Label>{messages.AssignMeters}</Form.Label>
                <Select
                  className="SelectBox"
                  placeholder="Select Meter"
                  options={meters}
                  value={assignMeters}
                  isMulti
                  isSearchable
                  onChange={handleAssignMeter}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="mt-5 form-inputs-div">
          <Row>
            <div className="sensor-ui">
              <h5>{messages.Devices}</h5>
              <i
                style={{ color: "#FF7538" }}
                className="fa fa-plus pointer"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Add Device"
                onClick={handleAddClick}
              ></i>
            </div>
          </Row>
          <Row>{Devices}</Row>
        </div>
      </Row>
      <Row>
        <Col lg={12} className="save-cancel-exit-btn">
          <button
            type="button"
            className="button-custom saveBtn"
            variant="primary"
            onClick={() => history.push("/Home/Organizations")}
          >
            {messages.cancel}
          </button>
          <button
            type="submit"
            className="button-custom saveBtn submitSaveBtn"
            variant="primary"
            // disabled={disable}
            onClick={submitHandler}
          >
            {messages.Save}
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default AddOrganization;
