import React, { useState } from "react";
import "../../../style/side-menu.css";
import "../../../style/body-component.css";
import { NavLink } from "react-router-dom";
import { messages } from "../../utils/constants";
import { useHistory, useLocation } from "react-router-dom";
// import { sideMenuSuperAdmin } from './side-menus-super-admin'
import {
  sideMenuSuperAdmin,
  sideMenuAdmin,
  sideMenuInstructor,
} from "../../utils/constant.json";
import ActionConfirmationPopup from "../../shared/action-confirmation-popup";

const SideMenu = ({ state }) => {
  const location = useLocation();
  const history = useHistory();
  const [popupValue, setPopupValue] = useState();
  const [cancelPopupShow, setCancelPopupShow] = useState(false);
  const sideMenuClick = (path) => {
    if (localStorage.getItem("cancelFormChanges") === "true") {
      setCancelPopupShow(true);
      setPopupValue({
        callBackFunction: () => {
          localStorage.removeItem("editSensorId");
          localStorage.removeItem("copyAndEditSensorId");
          localStorage.removeItem("cancelFormChanges");
          setCancelPopupShow(false);
          history.push(path);
        },
        headerName: "Alert",
        message: messages.cancelFormChanges,
        action: "Yes",
        cancelBtn: "No",
      });
    } else {
      history.push(path);
    }
  };
  const role = localStorage.getItem("role") || "";
  const menuData = (
    role === "1"
      ? sideMenuSuperAdmin
      : role === "2"
      ? sideMenuAdmin
      : role === "3"
      ? sideMenuInstructor
      : ""
  ).map((menu) => (
    <li
      key={menu.id}
      className={menu.cName}
      onClick={() => sideMenuClick(menu.path)}
    >
      <div
        className={
          menu.path === location.pathname
            ? "activeMenu sidebarLink"
            : "sidebarLink"
        }
      >
        <img src={menu.Icon} alt={menu.name} />
        {"  "}
        {menu.name}
      </div>
    </li>
  ));
  return (
    <div
      className={`sideNav ${state.width === "fullyOpened" && "fullyOpened"} ${
        state.width === "closed" && "closed"
      }`}
    >
      <ActionConfirmationPopup
        handleClose={() => setCancelPopupShow(false)}
        handleShow={() => setCancelPopupShow(true)}
        setShow={setCancelPopupShow}
        show={cancelPopupShow}
        message={cancelPopupShow ? popupValue.message : ""}
        action={cancelPopupShow ? popupValue.action : ""}
        deactivateFunction={() => popupValue.callBackFunction()}
        headerName={cancelPopupShow ? popupValue.headerName : ""}
        cancelBtn={cancelPopupShow ? popupValue.cancelBtn : ""}
      />
      <div className="sideHeadings">
        <ul className="menuItems">{menuData}</ul>
      </div>
    </div>
  );
};

export default SideMenu;
