import React, { memo, useMemo } from 'react'
import '../../style/style.css'
const Footer = () => {
    const year = useMemo(() => new Date().getFullYear(), []);
    return (
        <div className="footer">
            <p>Copyright © HazSim {year} All Rights Reserved</p>
        </div>
    )
}

export default memo(Footer);
