import { React, useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Login from "./jsx/login/Login";
import { MainWrapper } from "./jsx/main-wrapper/main-wrapper";
import { ProtectedRoutes } from "./ProtectedRoutes";
import 'react-toastify/dist/ReactToastify.css';
import ContactUs from "./jsx/contact-us/contact-us";
import ResetPassword from './jsx/set-new-password/setNewPassword.jsx'
//import Logout from './jsx/logout/logout'
import { ToastContainer } from 'react-toastify';
import axios from 'axios'

function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [sessionFetched, setSessionFetched] = useState(false)
  useEffect(() => {
    axios.post('/session/getLoggedInSession')
      .then(function (response) {
        let data = response.data ? response.data : {};
        setLoggedIn(data.login)
        setSessionFetched(true)
      })
      .catch(function (error) { })
  }, []);
  return (
    sessionFetched ?
      <>
        <Router>
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => <Login />}
            ></Route>
            <Route path="/ContactUs" render={(props) => <ContactUs />} />
            <Route
              path="/reset-password/"
              render={(props) => <ResetPassword />}
            ></Route>
            <ProtectedRoutes
              Path="/Home/"
              Component={MainWrapper}
              loggedIn={loggedIn}
            ></ProtectedRoutes>
            <Redirect from="*" to="/" />
          </Switch>
        </Router>
        <ToastContainer />
      </>
      :
      <div></div>
  );
}

export default App;
