import React, { useEffect, useState } from 'react'
import Spinner from '../spinner/spinner';
import axios from "axios"
import { ToastContainer } from 'react-toastify';
import auth from '../../auth'
import { useHistory } from "react-router-dom";
import '../../style/style.css'
import { messages } from "../utils/constants";
import { notify } from '../utils/utility'
import { ReactTitle } from "react-meta-tags";


const Logout = () => {
    const [spinner, setSpinner] = useState(true);
    const history = useHistory();

    useEffect(() => {
        logout();

    }, [])

    const logout = () => {
        axios
            .post("/session/logout")
            .then((res) => {
                setTimeout(() => {
                    auth.logout(() => {
                        localStorage.clear();
                        history.push("/");
                    })
                }, 1000);
            })
            .catch((error) => {
                console.log(error);
                notify(messages.ToastError)
            });
    }

    return (
        <div className="main-root">
            <ReactTitle title="Hazsim-Logout" />
            <ToastContainer />
            {spinner ? (<Spinner />) : ""}
        </div>
    )
}

export default Logout
