import React, { useState, useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import '../../style/style.css'
import Logo from "../../images/logo.png";
import axios from 'axios'
import profileInitialImage from '../../images/man.png'

const HeaderComponent = ({ state, setState }) => {

    const [profileSrc, setProfileSrc] = useState(localStorage.getItem('profileSrc') === "/upload/userProfile/" ? profileInitialImage : localStorage.getItem('profileSrc'));
    const [loggedInUserName, setLoggedInuserName] = useState(localStorage.getItem('loggedInUserName') || "");
    const [role, setRole] = useState(localStorage.getItem('role') || "");
    const [designation, setDesignation] = useState("");
    const [organizationName, setOrganizationName] = useState(localStorage.getItem('organizationName') || "");
    const [email, setEmail] = useState(localStorage.getItem('email') || "");
    useEffect(() => {
        if (role === "1") {
            setDesignation("Super Admin")
        }
        if (role === "2") {
            setDesignation("Organization Admin")
        }
        if (role === "3") {
            setDesignation("Instructor")
        }
        if (role === "4") {
            setDesignation("Student")
        }
    }, [])

    const ToggleBar = (state) => {
        if (state.width === 'fullyOpened') {
            setState({
                width: 'closed',
            })
        } else if (state.width === 'closed') {
            setState({
                width: 'fullyOpened',
            })
        }
    }
    const fetchLatestProfile = () => {
        setProfileSrc(localStorage.getItem('profileSrc') || "")
        setLoggedInuserName(localStorage.getItem('loggedInUserName'))
        setEmail(localStorage.getItem('email'))
        setOrganizationName(localStorage.getItem('organizationName'))
    }
    window.fetchLatestProfile = fetchLatestProfile;
    return (
        <Container fluid>
            <Row>
                <div className="header">
                    <div className="header-logo">
                        <img src={Logo} alt="logo" />
                    </div>
                    <div className="header-right-side">
                        <div className="right-side-menu">
                            <div className='d-flex align-items-center header-profile-info jusify-content-center'>
                                <button className="menuIcon" onClick={() => ToggleBar(state)}><i className="fas fa-bars"></i>
                                </button>
                                <h4 className='header-profile header-profile-info ' style={{color:"white",marginLeft:"8px"}}>{organizationName}</h4>
                            </div>
                            <div className="header-profile">
                                <div className="header-profile-info d-flex justify-content-start flex-column align-items-start ">
                                    <div className='d-flex align-items-center header-profile-info '>
                                        <h4>{loggedInUserName} </h4>
                                        <h6 style={{ fontSize: "12px", marginLeft: "4px" }}>
                                            ({email})
                                        </h6>
                                    </div>

                                    <div className='d-flex header-profile-info'>
                                        <h6>{designation}</h6>
                                    </div>
                                </div>
                                <div className="profile-icon-header">{profileSrc ? <img src={profileSrc} alt="" /> : <i className="fas fa-user-alt" aria-hidden="true"></i>}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </Container>
    )
}

export default HeaderComponent
