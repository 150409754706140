import { useState } from "react";


const useInputHook = (initialState) => {
  const [value, setValue] = useState(initialState);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return [value,
    handleChange]
};
export { useInputHook }