import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { ReactTitle } from "react-meta-tags";
import { messages } from "../utils/constants";
import "../../style/global.css";
import axios from "axios";
import { notify } from "../utils/utility";
import Spinner from "../spinner/spinner";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
const Settings = () => {
  const [cameraDefaultOffForOrg, setCameraDefaultOffForOrg] = useState(false);
  const [cameraDisableForOrg, setCameraDisableForOrg] = useState(false);
  const [loader, setLoader] = useState("getData");
  useEffect(() => {
    OrganizationDetails();
  }, []);

  const OrganizationDetails = () => {
    const url = "/Organizations/getUserOrganizationData";
    setLoader("update");
    axios
      .post(url)
      .then(function (response) {
        setLoader("");
        const cameraDefaultOffForOrg = response?.data?.cameraDefaultOffForOrg;
        const cameraDisableForOrg = response?.data?.cameraDisableForOrg;
        setCameraDefaultOffForOrg(cameraDefaultOffForOrg);
        setCameraDisableForOrg(cameraDisableForOrg);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateHandler = () => {
    const url = "/Organizations/updateMyOrg";
    axios
      .post(url, {
        //accessToken: accessToken,
        // id: organizationId,
        cameraDefaultOffForOrg,
      })
      .then(function (response) {
        setLoader("");
        let data = response.data ? response.data : {};
        if (data.errorCode) {
          notify(messages.ToastError, "server issue");
        } else {
          notify(messages.ToastSuccess, "Settings Updated");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div fluid className="container-fluid mt-3">
      {(loader === "update" || loader == "getData") && <Spinner />}
      <div className="row">
        <p className="font-bold mb-1"> {messages.Settings}</p>
      </div>
      <hr />
      <div className=" py-3 form-inputs-div px-4">
        <Form.Group className="gap-4">
          <Form.Label>Camera </Form.Label>
            <div className="font-bold">
              <label className="combineSwitch">
                <FormCheckInput
                  type="checkbox"
                  onChange={(e) =>
                    setCameraDefaultOffForOrg(!cameraDefaultOffForOrg)
                  }
                  checked={cameraDefaultOffForOrg}
                  disabled={cameraDisableForOrg}
                />
                <span className="slider round"></span>
              </label>
              <Form.Label className={`px-2`}>
                Default off for organization
              </Form.Label>
            </div>
        </Form.Group>
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className="saveBtn submitSaveBtn"
              variant="secondary"
              disabled={cameraDisableForOrg}
              onClick={updateHandler}
            >
              {messages.Save}
            </button>
          </div>
      </div>
    </div>
  );
};

export default Settings;
