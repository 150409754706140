import React from "react";
import { Container } from "react-bootstrap";
import { ReactTitle } from "react-meta-tags";
import Logo from "../../images/logo.png";
import { Link } from "react-router-dom";

function AboutHazsim() {
  return (
    <div>
      <Container fluid className="px-0 body-component pb-3">
        <ReactTitle title="Home-About-Hazsim" />
        <div className="container-fluid mt-3">
          <div className="about-us-logo">
            <img src={Logo} alt="logo" />
          </div>
          <h3 className="mt-3 font-bold">Hazsim Instructor Software</h3>
          <h6 className="font-bold">Version: 1.0.0 (1)</h6>
          <Link to={"#"} className="about-link pointer mt-4 mb-2">Terms & Conditions</Link>
          <Link to={"#"} className="about-link pointer mb-2">End User License Agreement(EULA)</Link>
          <Link to={"#"} className="about-link pointer mb-2">Privacy Policy</Link>
          <Link to={"/Home/Support"} className="about-link pointer mb-2">Support</Link>
        </div>
      </Container>
    </div>
  );
}
export default AboutHazsim;
