import {
  React,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";
// import { useHistory } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import { messages } from "../../utils/constants";
import axios from "axios";
import {
  notify,
  isMatches,
  focusById,
  isObjectId,
  toNumberOrNull,
  ifIsNumberOrNUll,
} from "../../utils/utility";
import _ from "lodash";
import "../../../style/style.css";

const SensorForm = (props, ref) => {
  // const history = useHistory();
  const invalidData = {
    sensorLabel: false,
    sensorPrecision: false,
    sensorBounce: false,
    sensorBounceInterval: false,
    sensorNormVal: false,
    sensorIncrement: false,
    sensorLumpIncrement: false,
    sensorMinVal: false,
    sensorInterVal: false,
    sensorLowAlarms: false,
    sensorLowLowAlarms: false,
    sensorMaxVal: false,
    sensorAlarm2Val: false,
  };
  const [invalid, setInvalid] = useState(invalidData);

  const [sensorLabel, setSensorLabel] = useState("");
  const [selectType, setSelectType] = useState(1);
  const [sensorUnit, setSensorUnit] = useState();
  const [sensorPrecision, setSensorPrecision] = useState(0);
  const [sensorBounce, setSensorBounce] = useState(0);
  const [sensorBounceInterval, setSensorBounceInterval] = useState(0);
  const [sensorNormVal, setSensorNormVal] = useState(0);
  const [sensorIncrement, setSensorIncrement] = useState(0);
  const [sensorLumpIncrement, setSensorLumpIncrement] = useState(0);
  const [sensorMinVal, setSensorMinVal] = useState(0);
  const [sensorInterVal, setSensorInterVal] = useState(null);
  const [sensorLowLowAlarms, setSensorLowLowAlarms] = useState(null);
  const [sensorLowAlarms, setSensorLowAlarms] = useState(null);
  const [sensorMaxVal, setSensorMaxVal] = useState("");
  const [sensorUnitOptn, setSensorUnitOptn] = useState([]);
  const [sensorUnits, setSensorUnits] = useState([{}]);
  const [noOfUnits, setNoOfUnits] = useState(1);
  const [unitsHtml, setUnitsHtml] = useState([]);
  const [editOption, setEditOption] = useState(false);
  const [operation, setOperation] = useState(1);
  const [sensorValue, setSensorValue] = useState(null);
  const [linkedSensor, setLinkedSensor] = useState(null);
  const [sensorAlarm2Val, setSensorAlarm2Val] = useState(null);
  const [normVUnitIndex, setNormVUnitIndex] = useState(0);
  const [lowAUnitIndex, setLowAUnitIndex] = useState(0);
  const [alarm1UnitIndex, setAlarm1UnitIndex] = useState(0);
  const [alarm2UnitIndex, setAlarm2UnitIndex] = useState(0);

  useEffect(() => {
    if (
      props.linkedSensorOptions &&
      props.linkedSensorOptions.length &&
      !linkedSensor
    ) {
      setLinkedSensor(props.linkedSensorOptions[0].value);
    }
  }, [props.linkedSensorOptions]);

  useImperativeHandle(ref, () => ({
    validData() {
      let isValid = validator();
      return isValid;
    },
    getValidatedData() {
      let data1 = {
        selectType: selectType || "",
        sensorLabel: sensorLabel || "",
        sensorPrecision:
          selectType == messages.sensorTypes.BAR
            ? 0
            : toNumberOrNull(sensorPrecision, ""),
        sensorBounce:
          selectType == messages.sensorTypes.BAR
            ? 0
            : toNumberOrNull(sensorBounce),
        sensorBounceInterval: toNumberOrNull(sensorBounceInterval),
        sensorNormVal: toNumberOrNull(sensorNormVal, ""),
        sensorIncrement: toNumberOrNull(sensorIncrement, ""),
        sensorLumpIncrement:
          selectType == messages.sensorTypes.BAR
            ? 0
            : toNumberOrNull(sensorLumpIncrement, ""),
        sensorMinVal:
          selectType == messages.sensorTypes.BAR
            ? 0
            : toNumberOrNull(sensorMinVal, ""),
        sensorInterVal: toNumberOrNull(sensorInterVal),
        sensorLowLowAlarms: toNumberOrNull(sensorLowLowAlarms),
        sensorLowAlarms: toNumberOrNull(sensorLowAlarms),
        sensorMaxVal: toNumberOrNull(sensorMaxVal, ""),
        linkedSensor: isObjectId(linkedSensor) ? linkedSensor : null,
        operation: operation || "",
        sensorValue: sensorValue || "",
        sensorAlarm2Val: toNumberOrNull(sensorAlarm2Val),
        normVUnitIndex: 0,
        lowAUnitIndex: 0,
        alarm1UnitIndex: 0,
        alarm2UnitIndex: 0,
      };
      if (selectType == messages.sensorTypes.EXPONENTIAL) {
        data1.sensorUnit = sensorUnits ? sensorUnits : [];
        data1.normVUnitIndex = toNumberOrNull(normVUnitIndex);
        data1.lowAUnitIndex = toNumberOrNull(lowAUnitIndex);
        data1.alarm1UnitIndex = toNumberOrNull(alarm1UnitIndex);
        data1.alarm2UnitIndex = toNumberOrNull(alarm2UnitIndex);
      } else {
        data1.sensorUnit = sensorUnit ? [{ id: sensorUnit }] : [];
      }

      return data1;
    },
  }));
  useEffect(() => {
    getSensorUnitList();
  }, []);

  useEffect(() => {
    if (props.editSensorData) {
      let data = props.editSensorData;
      setSelectType(data.type ? data.type : 1);
      setSensorLabel(data.label ? data.label : "");
      setSensorPrecision(toNumberOrNull(data.precision, 0));
      setSensorBounce(toNumberOrNull(data.bounce, 0));
      setSensorBounceInterval(toNumberOrNull(data.bounceInterval, 0));
      setSensorIncrement(toNumberOrNull(data.increment, 0));
      setSensorLumpIncrement(toNumberOrNull(data.jumpIncrement, 0));
      setSensorMinVal(toNumberOrNull(data.minValue, 0));
      setSensorNormVal(toNumberOrNull(data.normValue, 0));
      setSensorMaxVal(toNumberOrNull(data.maxValue, 0));
      setSensorInterVal(toNumberOrNull(data.interAlarmValue));
      setSensorLowAlarms(toNumberOrNull(data.sensorLowAlarms));
      setSensorLowLowAlarms(toNumberOrNull(data.sensorLowLowAlarms));
      setSensorAlarm2Val(toNumberOrNull(data.alarm2, 0));
      setLinkedSensor(data.linkedSensor ? data.linkedSensor : "");
      setOperation(data.operation ? data.operation : 1);
      setSensorValue(toNumberOrNull(data.sensorValue));
      setLowAUnitIndex(data.lowAUnitIndex);
      setNormVUnitIndex(data.normVUnitIndex);
      setAlarm1UnitIndex(data.alarm1UnitIndex);
      setAlarm2UnitIndex(data.alarm2UnitIndex);
      if (data.type === messages.sensorTypes.EXPONENTIAL) {
        let editUnits = data.units ? data.units : [];
        setNoOfUnits(editUnits.length);
        editUnits = editUnits.map((inst) => ({ id: inst?.id }));
        setSensorUnits(editUnits);
      } else {
        setSensorUnit(data?.units ? data?.units[0]?.id : "");
      }
      setEditOption(true);
    } else {
      clearState();
    }
  }, [props.editSensorData]);
  useEffect(() => {
    if (!editOption) {
      let sensorUnitsArray = Array.from(Array(+noOfUnits), (a, i) => ({
        id: sensorUnitOptn?.[i]?.value,
      }));

      setSensorUnits(sensorUnitsArray);
    }
  }, [noOfUnits]);

  useEffect(() => {
    if (selectType == messages.sensorTypes.EXPONENTIAL) {
      let unitsHtml = [];
      if (noOfUnits && sensorUnitOptn.length) {
        for (let i = 1; i <= parseInt(noOfUnits); i++) {
          unitsHtml.push(
            <Form.Group as={Row} className="mb-2" controlId={"sensorUnit-" + i}>
              <Form.Label column sm="4">
                {messages.addSensorUnits + " " + i}*
              </Form.Label>
              <Col sm="8">
                {props.action === "view" ? (
                  <Form.Label column sm="4">
                    {_.find(sensorUnitOptn, function (inst) {
                      return inst["value"] === (sensorUnits?.[i - 1]?.id || "");
                    }).label || ""}
                  </Form.Label>
                ) : (
                  <Form.Select
                    aria-label="Default select example"
                    value={sensorUnits?.[i - 1]?.id || ""}
                    onChange={changeUnits.bind("", i)}
                  >
                    {sensorUnitOptn.map((type, key) => (
                      <option key={"unit-" + i + key} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </Col>
            </Form.Group>
          );
        }
      }
      setUnitsHtml(unitsHtml);
    }
  }, [sensorUnits, selectType, sensorUnitOptn, props.action]);
  useEffect(() => {
    checkFieldValues();
  });
  const changeUnits = (i, e) => {
    let _sensorUnits = sensorUnits;
    let index = _sensorUnits.findIndex((inst) => inst.id === e.target.value);
    let selectedSensorLabel =
      e.target.options[e.target.selectedIndex].text || "This";
    if (index !== -1) {
      focusById("sensorUnit-" + i);
      notify(
        messages.ToastError,
        messages.InvalidSensorUnits(selectedSensorLabel)
      );
      return;
    }
    if (_sensorUnits[i - 1].id !== e.target.value) props?.setEditedForm?.();
    _sensorUnits[i - 1].id = e.target.value;
    setSensorUnits([..._sensorUnits]);
  };
  const clearState = () => {
    setSensorLabel("");
    setSelectType(1);
    setNoOfUnits(1);
    setSensorPrecision(0);
    setSensorBounce(0);
    setSensorBounceInterval(0);
    setSensorNormVal(0);
    setSensorIncrement(0);
    setSensorLumpIncrement(0);
    setSensorMinVal(0);
    setSensorInterVal(null);
    setSensorLowAlarms(null);
    setSensorLowLowAlarms(null);
    setSensorAlarm2Val(0);
    setSensorMaxVal(0);
    setSensorUnit();
    setUnitsHtml([]);
    setSensorUnits([{}]);
    setEditOption(false);
  };
  const getSensorUnitList = () => {
    const url = "/Sensors/getSensorUnitList";

    axios
      .post(url)
      .then((res) => {
        if (res.data) {
          let sensorUnitOptn = [];
          let data = res.data;
          for (let i in data) {
            let sensorUnitData = {};
            sensorUnitData.label = data[i].name;
            sensorUnitData.value = data[i].id.toString();
            sensorUnitOptn.push(sensorUnitData);
          }
          let defaultValue =
            props.editSensorData?.units?.[0]?.id ||
            sensorUnitOptn[0]?.value ||
            "";
          setSensorUnit(defaultValue);
          // if(!editOption){
          //     setSensorUnits([{ id: defaultValue }])
          // }
          setSensorUnitOptn(sensorUnitOptn);
          props?.setSensorUnitList?.(sensorUnitOptn);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectSensorType = (event) => {
    let value = toNumberOrNull(event.target.value);
    if (props?.setEditedForm) {
      props.setEditedForm();
    }
    if (
      value === messages.sensorTypes.CALCULATED &&
      (!props.linkedSensorOptions || !props.linkedSensorOptions.length)
    ) {
      notify(messages.ToastError, messages.CalculatedNotAvailable);
      return;
    } else if (value === messages.sensorTypes.EXPONENTIAL) {
      let sensorUnitsArray = Array.from(Array(+noOfUnits), (a, i) => ({
        id: sensorUnitOptn?.[i]?.value,
      }));
      setSensorUnits(sensorUnitsArray);
    }
    setSelectType(value);
    //checkFieldValues();
  };
  const handleSelectNoOfUnits = (event) => {
    if (props?.setEditedForm) {
      props.setEditedForm();
    }
    if (sensorUnitOptn.length) {
      setEditOption(false);
    }
    setNoOfUnits(event.target.value);
  };
  const handleSelectSensorUnit = (event) => {
    if (props?.setEditedForm) {
      props.setEditedForm();
    }
    setSensorUnit(event.target.value);
    // checkFieldValues();
  };
  const handleSelectLinkedSensor = (event) => {
    if (props?.setEditedForm) {
      props.setEditedForm();
    }
    setLinkedSensor(event.target.value);
  };
  const handleSelectOperation = (event) => {
    if (props?.setEditedForm) {
      props.setEditedForm();
    }
    setOperation(+event.target.value);
  };

  const handleNormVIndexChange = (val) => {
    setNormVUnitIndex(val);
  };

  const validator = () => {
    // console.log(
    //   { sensorLabel,
    //     sensorPrecision,
    //     sensorBounce,
    //     sensorBounceInterval,
    //     sensorNormVal,
    //     sensorIncrement,
    //     sensorLumpIncrement,
    //     sensorMinVal,
    //     sensorInterVal,
    //     sensorAlarm2Val,
    //     sensorMaxVal,
    //   }
    // )
    // return;
    let invalid = {
      sensorLabel: false,
      sensorPrecision: false,
      sensorBounce: false,
      sensorBounceInterval: false,
      sensorNormVal: false,
      sensorIncrement: false,
      sensorLumpIncrement: false,
      sensorMinVal: false,
      sensorInterVal: false,
      sensorAlarm2Val: false,
      sensorMaxVal: false,
      sensorLowLowAlarms: false,
      sensorLowAlarms: false,
      lowAUnitIndex: false,
      normVUnitIndex: false,
      alarm1UnitIndex: false,
      alarm2UnitIndex: false,
    };
    var flag = false;
    /*Validations*/
    if (selectType == messages.sensorTypes.EXPONENTIAL) {
      if (lowAUnitIndex > normVUnitIndex) {
        flag = invalid.lowAUnitIndex = true;
        notify(messages.ToastError, messages.InvalidLowAlarmUnit);
        return false;
      } else if (normVUnitIndex > alarm1UnitIndex) {
        flag = invalid.normVUnitIndex = true;
        notify(messages.ToastError, messages.InvalidNormalUnit);
        return false;
      } else if (alarm1UnitIndex > alarm2UnitIndex) {
        flag = invalid.alarm1UnitIndex = true;
        notify(messages.ToastError, messages.InvalidAlarm1Unit);
        return false;
      }
      if (toNumberOrNull(sensorMinVal) >= 1000 && noOfUnits > 0) {
        flag = invalid.sensorMinVal = true;
        notify(
          messages.ToastError,
          "Min alarm value can not be greater than or equal to 1000"
        );
        return false;
      }
      if (toNumberOrNull(sensorMinVal) < 0) {
        flag = invalid.sensorMinVal = true;
        notify(messages.ToastError, "Min alarm value can not be less than 0");
        return false;
      }

      if (lowAUnitIndex != noOfUnits - 1) {
        if (
          toNumberOrNull(sensorLowAlarms) !== null &&
          +sensorLowAlarms >= 1000
        ) {
          flag = invalid.sensorLowAlarms = true;
          notify(
            messages.ToastError,
            "Low alarm value can not be greater than or equal to 1000"
          );
          return false;
        }
      }
      if (normVUnitIndex != noOfUnits - 1) {
        if (toNumberOrNull(sensorNormVal) !== null && +sensorNormVal >= 1000) {
          flag = invalid.sensorNormVal = true;
          notify(
            messages.ToastError,
            "Normal alarm value can not be greater than or equal to 1000"
          );
          return false;
        }
      }
      if (alarm1UnitIndex != noOfUnits - 1) {
        if (
          toNumberOrNull(sensorInterVal) !== null &&
          +sensorInterVal >= 1000
        ) {
          flag = invalid.sensorInterVal = true;
          notify(
            messages.ToastError,
            "Alarm 1 value can not be greater than or equal to 1000"
          );
          return false;
        }
      }
      if (alarm2UnitIndex != noOfUnits - 1) {
        if (
          toNumberOrNull(sensorAlarm2Val) !== null &&
          +sensorAlarm2Val >= 1000
        ) {
          flag = invalid.sensorInterVal = true;
          notify(
            messages.ToastError,
            "Alarm 2 value can not be greater than or equal to 1000"
          );
          return false;
        }
      }
      if (
        lowAUnitIndex == normVUnitIndex &&
        normVUnitIndex == alarm1UnitIndex &&
        alarm1UnitIndex == alarm2UnitIndex &&
        lowAUnitIndex != noOfUnits - 1
      ) {
        if (
          toNumberOrNull(sensorLowAlarms) !== null &&
          +sensorLowAlarms >= 1000
        ) {
          flag = invalid.lowAUnitIndex = true;
          notify(
            messages.ToastError,
            "Low alarm value can not be greater than or equal to 1000"
          );
          return false;
        }

        if (toNumberOrNull(sensorNormVal) !== null && +sensorNormVal >= 1000) {
          flag = invalid.normVUnitIndex = true;
          notify(
            messages.ToastError,
            "Norm value can not be greater than or equal to 1000"
          );
          return false;
        }

        if (
          toNumberOrNull(sensorInterVal) !== null &&
          +sensorInterVal >= 1000
        ) {
          flag = invalid.lowAUnitIndex = true;
          notify(
            messages.ToastError,
            "Alarm 1 value can not be greater than or equal to 1000"
          );
          return false;
        }
      }
    }
    if (!isMatches(sensorLabel, messages.RegexAlphaNumericSpecialChar)) {
      flag = invalid.sensorLabel = true;
      focusById("sensorLabel");
      notify(messages.ToastError, messages.InvalidSensorLabel);
    }
    if (selectType != messages.sensorTypes.CALCULATED) {
      if (toNumberOrNull(sensorPrecision) === null || +sensorPrecision < 0) {
        flag = invalid.sensorPrecision = true;
        focusById("sensorPrecision");
        notify(messages.ToastError, messages.InvalidSensorPrecision);
      } else if (toNumberOrNull(sensorBounce) === null || +sensorBounce < 0) {
        flag = invalid.sensorBounce = true;
        focusById("sensorBounce");
        notify(messages.ToastError, messages.InvalidSensorBounce);
      } else if (
        toNumberOrNull(sensorBounceInterval) === null ||
        +sensorBounceInterval < 0
      ) {
        flag = invalid.sensorBounceInterval = true;
        focusById("sensorBounceInterval");
        notify(messages.ToastError, messages.InvalidSensorBounceInterval);
      } else if (
        toNumberOrNull(sensorIncrement) === null ||
        +sensorIncrement <= 0
      ) {
        flag = invalid.sensorIncrement = true;
        focusById("sensorIncrement");
        notify(messages.ToastError, messages.InvalidSensorIncrement);
      } else if (
        selectType != messages.sensorTypes.BAR &&
        (toNumberOrNull(sensorLumpIncrement) === null ||
          +sensorLumpIncrement <= 0)
      ) {
        flag = invalid.sensorLumpIncrement = true;
        focusById("sensorLumpIncrement");
        notify(messages.ToastError, messages.InvalidSensorLumpIncrement);
      } else if (
        toNumberOrNull(sensorMinVal) === null ||
        toNumberOrNull(sensorMinVal) >= 1000
      ) {
        // Min value
        flag = invalid.sensorMinVal = true;
        focusById("sensorMinVal");
        notify(messages.ToastError, messages.InvalidSensorMinVal);
      } else if (
        // Norm value
        toNumberOrNull(sensorNormVal) === null ||
        (+sensorMinVal > +sensorNormVal && normVUnitIndex == 0)
      ) {
        flag = invalid.sensorNormVal = true;
        focusById("sensorNormVal");
        notify(
          messages.ToastError,
          selectType == messages.sensorTypes.BAR
            ? messages.InvalidSensorNormValBar
            : messages.InvalidSensorNormVal
        );
      } else if (
        // Max
        toNumberOrNull(sensorMaxVal) === null ||
        (+sensorNormVal > +sensorMaxVal && normVUnitIndex == noOfUnits - 1) ||
        (+sensorMaxVal > 6 && selectType == messages.sensorTypes.BAR)
      ) {
        flag = invalid.sensorMaxVal = true;
        focusById("sensorMaxVal");
        notify(
          messages.ToastError,
          selectType == messages.sensorTypes.BAR
            ? messages.InvalidSensorNumberOfBars
            : messages.InvalidSensorMaxVal
        );
      } /*  else if (
        // Low low alarm
        !ifIsNumberOrNUll(sensorLowLowAlarms) ||
        (toNumberOrNull(sensorLowLowAlarms) !== null &&
          (+sensorLowLowAlarms < +sensorMinVal ||
            +sensorLowLowAlarms >= +sensorNormVal))
      ) {
        flag = invalid.sensorLowLowAlarms = true;
        focusById("sensorLowLowAlarms");
        notify(messages.ToastError, messages.InvalidSensorLowLowAlarmNorm);
      } */ else if (
        // low alarm
        !ifIsNumberOrNUll(sensorLowAlarms) ||
        (toNumberOrNull(sensorLowAlarms) !== null &&
          +sensorLowAlarms <= +sensorMinVal &&
          lowAUnitIndex == 0) ||
        (+sensorLowAlarms >= +sensorNormVal && lowAUnitIndex == normVUnitIndex)
      ) {
        flag = invalid.sensorLowAlarms = true;
        focusById("sensorLowAlarms");
        notify(
          messages.ToastError,
          selectType == messages.sensorTypes.BAR
            ? messages.InvalidSensorLowAlarmNormBar
            : messages.InvalidSensorLowAlarmNorm
        );
      } /*  else if (
        // low alarm
        toNumberOrNull(sensorLowLowAlarms) !== null &&
        toNumberOrNull(sensorLowAlarms) !== null &&
        +sensorLowLowAlarms >= +sensorLowAlarms
      ) {
        flag = invalid.sensorLowAlarms = true;
        focusById("sensorLowAlarms");
        notify(messages.ToastError, messages.InvalidSensorLowAlarmLLA);
      } */ else if (
        // Alarm 2
        toNumberOrNull(sensorAlarm2Val) === null ||
        (+sensorAlarm2Val <= +sensorNormVal &&
          alarm2UnitIndex == normVUnitIndex) ||
        (+sensorAlarm2Val > +sensorMaxVal && alarm2UnitIndex == noOfUnits - 1)
      ) {
        flag = invalid.sensorAlarm2Val = true;
        focusById("sensorAlarm2Val");
        notify(messages.ToastError, messages.InvalidSensorAlarm2Val2);
      } else if (
        // Alarm 1
        !ifIsNumberOrNUll(sensorInterVal) ||
        (toNumberOrNull(sensorInterVal) !== null &&
          ((+sensorInterVal <= +sensorNormVal &&
            normVUnitIndex === alarm1UnitIndex) ||
            (+sensorAlarm2Val <= +sensorInterVal &&
              alarm2UnitIndex === alarm1UnitIndex)))
      ) {
        flag = invalid.sensorInterVal = true;
        focusById("sensorInterVal");
        notify(messages.ToastError, messages.InvalidSensorInterVal);
      }
    } else {
      if (sensorValue < 0) {
        flag = invalid.sensorPrecision = true;
        focusById("sensorValue");
        notify(messages.ToastError, messages.InvalidSensorValue);
      }
    }
    setInvalid(invalid);
    return flag ? false : true;
  };

  const checkFieldValues = () => {
    let disable = !sensorLabel || !selectType;
    if (selectType != messages.sensorTypes.CALCULATED) {
      if (selectType == messages.sensorTypes.EXPONENTIAL) {
        disable =
          disable ||
          !sensorUnit ||
          [
            sensorPrecision,
            sensorBounce,
            sensorNormVal,
            sensorBounceInterval,
            sensorIncrement,
            sensorLumpIncrement,
            sensorMinVal,
            sensorMaxVal,
            sensorAlarm2Val,
          ]
            .map((i) => toNumberOrNull(i))
            .includes(null);
      }
      if (selectType == messages.sensorTypes.BAR) {
        disable =
          disable ||
          [sensorNormVal, sensorIncrement, sensorMaxVal, sensorAlarm2Val]
            .map((i) => toNumberOrNull(i))
            .includes(null);
      } else {
        disable =
          disable ||
          !sensorUnit ||
          [
            sensorPrecision,
            sensorBounce,
            sensorNormVal,
            sensorIncrement,
            sensorLumpIncrement,
            sensorMinVal,
            sensorMaxVal,
            sensorAlarm2Val,
          ]
            .map((i) => toNumberOrNull(i))
            .includes(null);
      }
    } else {
      disable =
        disable ||
        !linkedSensor ||
        !operation ||
        toNumberOrNull(sensorValue) === null;
    }
    props.enableDisableCallback(disable); //true: Disable te button
  };
  const onChangeHandler = (stateName, value, isNumber = false) => {
    stateName(isNumber ? toNumberOrNull(value) : value);
    if (props.setEditedForm) props.setEditedForm();
  };
  let sensorTypeOptions = useMemo(() => {
    let data = [...messages.sensorTypeOptions];
    if (!props.showCalculated) {
      return data.slice(0, 3);
    }
    return data.slice(0, 4);
  }, [props.showCalculated]);
  useEffect(() => {
    if (selectType == messages.sensorTypes.BAR) {
      setSensorIncrement(
        toNumberOrNull(sensorIncrement) ? Math.round(sensorIncrement) : 1
      );
      setSensorMinVal(0);
      setSensorNormVal(sensorNormVal && Math.round(sensorNormVal));
      setSensorMaxVal(sensorMaxVal && Math.min(Math.round(sensorMaxVal), 6));
      setSensorInterVal(
        toNumberOrNull(sensorInterVal) !== null
          ? Math.round(sensorInterVal)
          : null
      );
      setSensorLowAlarms(
        toNumberOrNull(sensorLowAlarms) !== null
          ? Math.round(sensorLowAlarms)
          : null
      );
      setSensorLowLowAlarms(
        toNumberOrNull(sensorLowLowAlarms) !== null
          ? Math.round(sensorLowLowAlarms)
          : null
      );
      setSensorAlarm2Val(
        toNumberOrNull(sensorAlarm2Val) !== null
          ? Math.round(sensorAlarm2Val)
          : null
      );
    } else {
      setSensorIncrement(sensorIncrement);
    }
  }, [selectType]);
  const incrementInputKeyDownChange = (e) => {
    if (e.key === ".") e.preventDefault();
    e.target.value = e.target.value.replace(/[^0-9]*/g, "");
  };

  return (
    <Form className="row">
      <div className="mt-3">
        <Row>
          <Col lg={12}>
            <div className="main-form-section">
              <div className="heading">{messages.BasicDetails}</div>
              <Form.Group as={Row} className="mb-2" controlId="sensorLabel">
                <Form.Label column sm="4">
                  {messages.addSensorLabel}*
                </Form.Label>
                <Col sm="8">
                  {props.action === "view" ? (
                    <Form.Label column sm="4">
                      {sensorLabel}
                    </Form.Label>
                  ) : (
                    <Form.Control
                      type="text"
                      value={sensorLabel}
                      onChange={(e) => {
                        onChangeHandler(setSensorLabel, e.target.value);
                      }}
                      maxLength={500}
                      className={invalid.sensorLabel ? " is-invalid" : ""}
                    />
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-2" controlId="type">
                <Form.Label column sm="4">
                  {messages.addSensorType}*
                </Form.Label>
                <Col sm="8">
                  {props.action === "view" ? (
                    <Form.Label column sm="4">
                      {_.find(sensorTypeOptions, function (inst) {
                        return inst["value"] == selectType;
                      })?.label || ""}
                    </Form.Label>
                  ) : (
                    <Form.Select
                      aria-label="Default select example"
                      value={selectType}
                      onChange={handleSelectSensorType}
                    >
                      {sensorTypeOptions.map((type, key) => (
                        <option key={"t-" + key} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </Col>
              </Form.Group>
              {selectType != messages.sensorTypes.CALCULATED ? (
                <>
                  {selectType == messages.sensorTypes.EXPONENTIAL ? (
                    <>
                      <Form.Group
                        as={Row}
                        className="mb-2"
                        controlId="noOfUnits"
                      >
                        <Form.Label column sm="4">
                          {messages.addSensorNoOfUnits}*
                        </Form.Label>
                        <Col sm="8">
                          {props.action === "view" ? (
                            <Form.Label column sm="4">
                              {_.find(
                                messages.sensorNoOfUnitsOptions,
                                function (inst) {
                                  return inst["value"] === noOfUnits;
                                }
                              ).label || ""}
                            </Form.Label>
                          ) : (
                            <Form.Select
                              aria-label="Default select example"
                              value={noOfUnits}
                              onChange={handleSelectNoOfUnits}
                            >
                              {messages.sensorNoOfUnitsOptions.map(
                                (type, key) => (
                                  <option key={"nou-" + key} value={type.value}>
                                    {type.label}
                                  </option>
                                )
                              )}
                            </Form.Select>
                          )}
                        </Col>
                      </Form.Group>
                      {unitsHtml}
                    </>
                  ) : (
                    selectType != messages.sensorTypes.BAR && (
                      <Form.Group
                        as={Row}
                        className="mb-2"
                        controlId="sensorUnit"
                      >
                        <Form.Label column sm="4">
                          {messages.addSensorUnits}*
                        </Form.Label>
                        <Col sm="8">
                          {props.action === "view" && sensorUnitOptn.length ? (
                            <Form.Label column sm="4">
                              {(
                                _.find(sensorUnitOptn, function (inst) {
                                  return inst["value"] === sensorUnit;
                                }) || []
                              ).label || ""}
                            </Form.Label>
                          ) : (
                            <Form.Select
                              aria-label="Default select example"
                              id="type"
                              value={sensorUnit}
                              onChange={handleSelectSensorUnit}
                            >
                              {sensorUnitOptn.map((type, key) => (
                                <option key={"u-" + key} value={type.value}>
                                  {type.label}
                                </option>
                              ))}
                            </Form.Select>
                          )}
                        </Col>
                      </Form.Group>
                    )
                  )}
                  {selectType != messages.sensorTypes.BAR && (
                    <Form.Group
                      as={Row}
                      className="mb-2"
                      controlId="sensorPrecision"
                    >
                      <Form.Label column sm="4">
                        {messages.addSensorPrecision}*
                      </Form.Label>
                      <Col sm="8">
                        {props.action === "view" ? (
                          <Form.Label column sm="4">
                            {sensorPrecision}
                          </Form.Label>
                        ) : (
                          <Form.Control
                            type="number"
                            value={sensorPrecision}
                            onChange={(e) => {
                              onChangeHandler(
                                setSensorPrecision,
                                parseInt(e.target.value),
                                true
                              );
                            }}
                            min="0"
                            maxLength={500}
                            className={
                              invalid.sensorPrecision ? " is-invalid" : ""
                            }
                          />
                        )}
                      </Col>
                    </Form.Group>
                  )}
                  {selectType != messages.sensorTypes.BAR && (
                    <Form.Group
                      as={Row}
                      className="mb-2"
                      controlId="sensorBounce"
                    >
                      <Form.Label column sm="4">
                        {messages.addSensorBounce}*
                      </Form.Label>
                      <Col sm="8">
                        {props.action === "view" ? (
                          <Form.Label column sm="4">
                            {sensorBounce}
                          </Form.Label>
                        ) : (
                          <Form.Control
                            type="number"
                            value={sensorBounce}
                            onChange={(e) => {
                              onChangeHandler(
                                setSensorBounce,
                                e.target.value,
                                true
                              );
                            }}
                            min="0"
                            maxLength={500}
                            className={
                              invalid.sensorBounce ? " is-invalid" : ""
                            }
                          />
                        )}
                      </Col>
                    </Form.Group>
                  )}

                  <Form.Group
                    as={Row}
                    className="mb-2"
                    controlId="sensorBounceInterval"
                  >
                    <Form.Label column sm="4">
                      {messages.addSensorBounceInterval}*
                    </Form.Label>
                    <Col sm="8">
                      {props.action === "view" ? (
                        <Form.Label column sm="4">
                          {sensorBounceInterval}
                        </Form.Label>
                      ) : (
                        <Form.Control
                          type="number"
                          value={sensorBounceInterval}
                          onChange={(e) => {
                            onChangeHandler(
                              setSensorBounceInterval,
                              e.target.value,
                              true
                            );
                          }}
                          min="0"
                          maxLength={500}
                          className={
                            invalid.sensorBounceInterval ? " is-invalid" : ""
                          }
                        />
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-2"
                    controlId="sensorIncrement"
                  >
                    <Form.Label column sm="4">
                      {messages.addSensorIncrement}*
                    </Form.Label>
                    <Col sm="8">
                      {props.action === "view" ? (
                        <Form.Label column sm="4">
                          {sensorIncrement}
                        </Form.Label>
                      ) : (
                        <Form.Control
                          type="number"
                          value={sensorIncrement}
                          onKeyDown={(e) =>
                            selectType == messages.sensorTypes.BAR
                              ? incrementInputKeyDownChange(e)
                              : {}
                          }
                          onInput={(e) =>
                            selectType == messages.sensorTypes.BAR
                              ? incrementInputKeyDownChange(e)
                              : {}
                          }
                          onChange={(e) => {
                            onChangeHandler(
                              setSensorIncrement,
                              e.target.value,
                              true
                            );
                          }}
                          min={selectType == messages.sensorTypes.BAR ? 1 : "0"}
                          maxLength={500}
                          className={
                            invalid.sensorIncrement ? " is-invalid" : ""
                          }
                        />
                      )}
                    </Col>
                  </Form.Group>
                  {selectType != messages.sensorTypes.BAR && (
                    <Form.Group
                      as={Row}
                      className="mb-2"
                      controlId="sensorLumpIncrement"
                    >
                      <Form.Label column sm="4">
                        {messages.addSensorLumpIncrement}*
                      </Form.Label>
                      <Col sm="8">
                        {props.action === "view" ? (
                          <Form.Label column sm="4">
                            {sensorLumpIncrement}
                          </Form.Label>
                        ) : (
                          <Form.Control
                            type="number"
                            value={sensorLumpIncrement}
                            onChange={(e) => {
                              onChangeHandler(
                                setSensorLumpIncrement,
                                e.target.value,
                                true
                              );
                            }}
                            min="0"
                            maxLength={500}
                            className={
                              invalid.sensorLumpIncrement ? " is-invalid" : ""
                            }
                          />
                        )}
                      </Col>
                    </Form.Group>
                  )}
                </>
              ) : (
                <>
                  <Form.Group
                    as={Row}
                    className="mb-2"
                    controlId="sensorLinkedSensor"
                  >
                    <Form.Label column sm="4">
                      {messages.addSensorLinkedSensor}*
                    </Form.Label>
                    <Col sm="8">
                      {props.action === "view" &&
                      props.linkedSensorOptions.length ? (
                        <Form.Label column sm="4">
                          {(
                            _.find(props.linkedSensorOptions, function (inst) {
                              return inst["id"] === linkedSensor.id;
                            }) || []
                          ).name || ""}
                        </Form.Label>
                      ) : (
                        <Form.Select
                          aria-label="Default select example"
                          value={linkedSensor}
                          onChange={handleSelectLinkedSensor}
                        >
                          {props.linkedSensorOptions.map((type, key) => (
                            <option key={"ls-" + key} value={type.value}>
                              {type.name}
                            </option>
                          ))}
                        </Form.Select>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-2"
                    controlId="sensorOperation"
                  >
                    <Form.Label column sm="4">
                      {messages.addSensorOperation}*
                    </Form.Label>
                    <Col sm="8">
                      {props.action === "view" ? (
                        <Form.Label column sm="4">
                          {operation === 1 ? (
                            <>Add(&#43;)</>
                          ) : operation === 2 ? (
                            <>Subtract(&#8722;)</>
                          ) : operation === 3 ? (
                            <>Multiply(&#215;)</>
                          ) : operation === 4 ? (
                            <>Divide(&#247;)</>
                          ) : (
                            ""
                          )}
                        </Form.Label>
                      ) : (
                        <Form.Select
                          aria-label="Default select example"
                          value={operation}
                          onChange={handleSelectOperation}
                        >
                          <option key={"o-plus"} value={1}>
                            Add(&#43;)
                          </option>
                          <option key={"o-minus"} value={2}>
                            Subtract(&#8722;)
                          </option>
                          <option key={"o-multi"} value={3}>
                            Multiply(&#215;)
                          </option>
                          <option key={"o-divide"} value={4}>
                            Divide(&#247;)
                          </option>
                        </Form.Select>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-2" controlId="sensorValue">
                    <Form.Label column sm="4">
                      {messages.addSensorValue}*
                    </Form.Label>
                    <Col sm="8">
                      {props.action === "view" ? (
                        <Form.Label column sm="4">
                          {sensorValue}
                        </Form.Label>
                      ) : (
                        <Form.Control
                          type="number"
                          value={sensorValue}
                          onChange={(e) => {
                            onChangeHandler(
                              setSensorValue,
                              e.target.value,
                              true
                            );
                          }}
                          min="0"
                          className={invalid.sensorMinVal ? " is-invalid" : ""}
                        />
                      )}
                    </Col>
                  </Form.Group>
                </>
              )}
            </div>
            {+selectType !== messages.sensorTypes.CALCULATED ? (
              <div className="main-form-section">
                <div className="heading">{messages.Range}</div>
                {selectType != messages.sensorTypes.BAR && (
                  <Form.Group
                    as={Row}
                    className="mb-2"
                    controlId="sensorMinVal"
                  >
                    <Form.Label column sm="4">
                      {messages.addSensorMinValue}*
                    </Form.Label>

                    <Col sm="8" className="position-relative">
                      <Form.Control
                        type="number"
                        value={sensorMinVal}
                        onChange={(e) => {
                          onChangeHandler(
                            setSensorMinVal,
                            e.target.value,
                            true
                          );
                        }}
                        onKeyDown={(e) =>
                          selectType == messages.sensorTypes.BAR
                            ? incrementInputKeyDownChange(e)
                            : {}
                        }
                        onInput={(e) =>
                          selectType == messages.sensorTypes.BAR
                            ? incrementInputKeyDownChange(e)
                            : {}
                        }
                        maxLength={500}
                        className={invalid.sensorMinVal ? " is-invalid" : ""}
                      />
                      {selectType == messages.sensorTypes.STANDARD && (
                        <Form.Label className="unit-label">
                          {_.find(sensorUnitOptn, function (inst) {
                            return inst.value == sensorUnit;
                          })?.label || ""}
                        </Form.Label>
                      )}
                      {selectType == messages.sensorTypes.EXPONENTIAL && (
                        <Form.Label className="unit-label">
                          {_.find(sensorUnitOptn, function (inst) {
                            return inst.value == sensorUnits[0].id;
                          })?.label || ""}
                        </Form.Label>
                      )}
                    </Col>
                  </Form.Group>
                )}
                <Form.Group as={Row} className="mb-2" controlId="sensorNormVal">
                  <Form.Label column sm="4">
                    {messages.addSensorNormValue}*
                  </Form.Label>
                  <Col sm="8" className="position-relative">
                    {/* {props.action === "view" ? (
                    <Form.Label column sm="4">
                      {sensorNormVal}
                    </Form.Label>
                  ) : (
                  )} */}
                    <Form.Control
                      type="number"
                      value={sensorNormVal}
                      onChange={(e) => {
                        onChangeHandler(setSensorNormVal, e.target.value, true);
                      }}
                      onKeyDown={(e) =>
                        selectType == messages.sensorTypes.BAR
                          ? incrementInputKeyDownChange(e)
                          : {}
                      }
                      onInput={(e) =>
                        selectType == messages.sensorTypes.BAR
                          ? incrementInputKeyDownChange(e)
                          : {}
                      }
                      min="0"
                      maxLength={500}
                      className={invalid.sensorNormVal ? " is-invalid" : ""}
                    />

                    {selectType == messages.sensorTypes.STANDARD && (
                      <Form.Label className="unit-label">
                        {_.find(sensorUnitOptn, function (inst) {
                          return inst.value == sensorUnit;
                        })?.label || ""}
                      </Form.Label>
                    )}
                    {selectType == messages.sensorTypes.EXPONENTIAL && (
                      <Form.Select
                        value={normVUnitIndex}
                        className="unit-select"
                        onChange={(e) => {
                          onChangeHandler(
                            handleNormVIndexChange,
                            e.target.value
                          );
                        }}
                      >
                        {sensorUnits.map((type, key) => {
                          return (
                            <option key={"unit-" + key} value={key}>
                              {_.find(sensorUnitOptn, function (inst) {
                                return inst.value == type.id;
                              })?.label || ""}
                            </option>
                          );
                        })}
                      </Form.Select>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" controlId="sensorMaxVal">
                  <Form.Label column sm="4">
                    {selectType != messages.sensorTypes.BAR
                      ? messages.addSensorMaxValue + "*"
                      : messages.numberOfBars + "*"}
                  </Form.Label>
                  <Col sm="8" className="position-relative">
                    {/* {props.action === "view" ? (
                    <Form.Label column sm="4">
                      {sensorMaxVal}
                    </Form.Label>
                  ) : (
                  )} */}
                    <Form.Control
                      type="number"
                      value={sensorMaxVal}
                      onChange={(e) => {
                        onChangeHandler(setSensorMaxVal, e.target.value, true);
                      }}
                      onKeyDown={(e) =>
                        selectType == messages.sensorTypes.BAR
                          ? incrementInputKeyDownChange(e)
                          : {}
                      }
                      onInput={(e) =>
                        selectType == messages.sensorTypes.BAR
                          ? incrementInputKeyDownChange(e)
                          : {}
                      }
                      min="0"
                      max="6"
                      className={invalid.sensorMaxVal ? " is-invalid" : ""}
                    />

                    <Form.Label
                      value={sensorUnits[sensorUnits.length - 1 || sensorUnit]}
                      className="unit-label"
                    >
                      {_.find(sensorUnitOptn, function (inst) {
                        return selectType == messages.sensorTypes.EXPONENTIAL
                          ? inst.value == sensorUnits[sensorUnits.length - 1].id
                          : selectType == messages.sensorTypes.STANDARD
                          ? inst.value == sensorUnit
                          : "";
                      })?.label || ""}
                    </Form.Label>
                  </Col>
                </Form.Group>
              </div>
            ) : (
              ""
            )}
            {+selectType !== messages.sensorTypes.CALCULATED ? (
              <div className="main-form-section">
                <div className="heading">{messages.Alarm}</div>
                {/* <Form.Group as={Row} className="mb-2" controlId="sensorLowLowAlarm">
                <Form.Label column sm="4">
                  {messages.addSensorAlarmLowLowValue} (Optional)
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    placeholder="--"
                    value={sensorLowLowAlarms}
                    onChange={(e) => {
                      onChangeHandler(setSensorLowLowAlarms, e.target.value);
                    }}
                    onKeyDown={(e) => selectType == messages.sensorTypes.BAR ? incrementInputKeyDownChange(e) : {}}
                    onInput={(e) => selectType == messages.sensorTypes.BAR ? incrementInputKeyDownChange(e) : {}}
                    min={sensorMinVal}
                    maxLength={500}
                    className={invalid.sensorLowLowAlarms ? " is-invalid" : ""}
                  />
                </Col>
              </Form.Group> */}
                <Form.Group
                  as={Row}
                  className="mb-2"
                  controlId="sensorLowAlarm"
                >
                  <Form.Label column sm="4">
                    {messages.addSensorAlarmLowValue} (Optional)
                  </Form.Label>
                  <Col sm="8" className="position-relative">
                    <Form.Control
                      type="number"
                      placeholder="--"
                      value={sensorLowAlarms}
                      onChange={(e) => {
                        onChangeHandler(
                          setSensorLowAlarms,
                          e.target.value,
                          true
                        );
                      }}
                      onKeyDown={(e) =>
                        selectType == messages.sensorTypes.BAR
                          ? incrementInputKeyDownChange(e)
                          : {}
                      }
                      onInput={(e) =>
                        selectType == messages.sensorTypes.BAR
                          ? incrementInputKeyDownChange(e)
                          : {}
                      }
                      min={
                        selectType == messages.sensorTypes.BAR ? "0" : void 0
                      }
                      maxLength={500}
                      className={
                        "hide-number-control " +
                        (invalid.sensorLowAlarms ? " is-invalid" : "")
                      }
                    />
                    {selectType == messages.sensorTypes.STANDARD && (
                      <Form.Label className="unit-label">
                        {_.find(sensorUnitOptn, function (inst) {
                          return inst.value == sensorUnit;
                        })?.label || ""}
                      </Form.Label>
                    )}
                    {selectType == messages.sensorTypes.EXPONENTIAL && (
                      <Form.Select
                        value={lowAUnitIndex}
                        className="unit-select"
                        onChange={(e) => {
                          onChangeHandler(setLowAUnitIndex, e.target.value);
                        }}
                      >
                        {sensorUnits.map((type, key) => {
                          return (
                            <option key={"unit-" + key} value={key}>
                              {_.find(sensorUnitOptn, function (inst) {
                                return inst.value == type.id;
                              })?.label || ""}
                            </option>
                          );
                        })}
                      </Form.Select>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-2"
                  controlId="sensorInterVal"
                >
                  <Form.Label column sm="4">
                    {messages.addSensorInterValue} (Optional)
                  </Form.Label>
                  <Col sm="8" className="position-relative">
                    {/* {props.action === "view" && !props.allowAlarmsOnly ? (
                    <Form.Label column sm="4">
                      {sensorInterVal}
                    </Form.Label>
                  ) : (
                  )} */}
                    <Form.Control
                      type="number"
                      placeholder="--"
                      value={sensorInterVal}
                      onChange={(e) => {
                        onChangeHandler(
                          setSensorInterVal,
                          e.target.value,
                          true
                        );
                      }}
                      onKeyDown={(e) =>
                        selectType == messages.sensorTypes.BAR
                          ? incrementInputKeyDownChange(e)
                          : {}
                      }
                      onInput={(e) =>
                        selectType == messages.sensorTypes.BAR
                          ? incrementInputKeyDownChange(e)
                          : {}
                      }
                      min={
                        selectType == messages.sensorTypes.BAR ? "0" : void 0
                      }
                      maxLength={500}
                      className={
                        "hide-number-control " +
                        (invalid.sensorInterVal ? " is-invalid" : "")
                      }
                    />
                    {selectType == messages.sensorTypes.STANDARD && (
                      <Form.Label className="unit-label">
                        {_.find(sensorUnitOptn, function (inst) {
                          return inst.value == sensorUnit;
                        })?.label || ""}
                      </Form.Label>
                    )}
                    {selectType == messages.sensorTypes.EXPONENTIAL && (
                      <Form.Select
                        value={alarm1UnitIndex}
                        className="unit-select"
                        onChange={(e) => {
                          onChangeHandler(setAlarm1UnitIndex, e.target.value);
                        }}
                      >
                        {sensorUnits.map((type, key) => {
                          return (
                            <option
                              key={"unit-" + key}
                              value={sensorUnits.indexOf(type)}
                            >
                              {_.find(sensorUnitOptn, function (inst) {
                                return inst.value == type.id;
                              })?.label || ""}
                            </option>
                          );
                        })}
                      </Form.Select>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-2"
                  controlId="sensorAlarm2Val"
                >
                  <Form.Label column sm="4">
                    {" "}
                    {messages.addSensorAlarm2}*{" "}
                  </Form.Label>
                  <Col sm="8" className="position-relative">
                    {/* {props.action === "view" ? (<Form.Label column sm="4">{sensorAlarm2Val}</Form.Label>) : (
                )} */}
                    <Form.Control
                      type="number"
                      placeholder="--"
                      value={sensorAlarm2Val}
                      onChange={(e) => {
                        onChangeHandler(
                          setSensorAlarm2Val,
                          e.target.value ? e.target.value : "",
                          true
                        );
                      }}
                      onKeyDown={(e) =>
                        selectType == messages.sensorTypes.BAR
                          ? incrementInputKeyDownChange(e)
                          : {}
                      }
                      onInput={(e) =>
                        selectType == messages.sensorTypes.BAR
                          ? incrementInputKeyDownChange(e)
                          : {}
                      }
                      min={
                        selectType == messages.sensorTypes.BAR ? "0" : void 0
                      }
                      maxLength={500}
                      className={
                        "hide-number-control " +
                        (invalid.sensorAlarm2Val ? " is-invalid" : "")
                      }
                    />
                    {selectType == messages.sensorTypes.STANDARD && (
                      <Form.Label className="unit-label">
                        {_.find(sensorUnitOptn, function (inst) {
                          return inst.value == sensorUnit;
                        })?.label || ""}
                      </Form.Label>
                    )}
                    {selectType == messages.sensorTypes.EXPONENTIAL && (
                      <Form.Select
                        value={alarm2UnitIndex}
                        className="unit-select"
                        onChange={(e) => {
                          onChangeHandler(setAlarm2UnitIndex, e.target.value);
                        }}
                      >
                        {sensorUnits.map((type, key) => {
                          return (
                            <option
                              key={"unit-" + key}
                              value={sensorUnits.indexOf(type)}
                            >
                              {_.find(sensorUnitOptn, function (inst) {
                                return inst.value == type.id;
                              })?.label || ""}
                            </option>
                          );
                        })}
                      </Form.Select>
                    )}
                  </Col>
                </Form.Group>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default forwardRef(SensorForm);
