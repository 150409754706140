import React, { useEffect, useState } from 'react'
import { Row, Col, Container, Nav } from 'react-bootstrap'
import { messages } from "../../utils/constants";
import { Link } from 'react-router-dom';
import axios from 'axios'

const ReferenceLink = () => {
    const [type, setType] = useState(5);
    const [reference, setReference] = useState([{}]);

    useEffect(() => {
        getSupportDocuments();
    }, [type])

    const getSupportDocuments = () => {
        axios.post("/documents/getDocumentList", { type: type }).then((res) => {
            if (res.data) {

                let rows = [];
                let data = res.data;
                for (let i in data) {
                    let ManualsData = {};
                    ManualsData.name = data[i].name;
                    ManualsData.content = data[i].content;
                    rows.push(ManualsData);
                }
                setReference(rows)
            }
        })
    }


    const ReferenceData = reference.map((mn) => {

        return (
            <li><a target="_blank" href={mn.content}>{mn.name}</a></li>
        )

    })

    return (
        <>
            <Container className="manuals-root">
                <Row>
                    <h6 className="text-center manual-heading">{messages.ReferenceLink}</h6>
                </Row>
                <hr />
                <Row className="mt-4 manual-data">
                    <ul className="reference-links">
                        {ReferenceData}
                    </ul>
                </Row>
            </Container>

        </>
    )
}

export default ReferenceLink
