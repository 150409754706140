import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import ReactGrid from "../grid/reactGrid";
import "../../style/style.css";
import { messages } from "../utils/constants";
import ShowEntries from "../utils/show-entries";
import Pagination from "react-js-pagination";
import GridFilter from "../grid/grid-filter";
import Spinner from "../spinner/spinner";
import { notify } from '../utils/utility'
import ActionConfirmationPopup from "../shared/action-confirmation-popup";
import axios from "axios";
import { ReactTitle } from "react-meta-tags";


function SensorList({ state }) {
    const history = useHistory()
    const [spinner, setSpinner] = useState(false);
    const [show, setShow] = useState(false);
    const [sensorStatus, setSensorStatus] = useState("");
    const [sensorName, setSensorName] = useState("");
    const [sensorId, setSensorId] = useState("");
    const [searchContent, setSearchContent] = useState("");
    const [forAlarms, setForAlarms] = useState(false);
    const [fields, setFields] = useState([]);
    const [showTotalCount, setShowTotalCount] = useState(true);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(
        messages.GridRowCountOptions[0]
    );
    const [status, setStatus] = useState(1);
    const [totalCount, setTotalCount] = useState();
    const [activePage, setActivePage] = useState(1);
    const [skip, setSkip] = useState(0);

    const role = localStorage.getItem("role");
    const organizationId = localStorage.getItem("organizationId");
    const userId = localStorage.getItem("userId");


    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };
    function GridActions(props) {
        const status = () => {
            setOpen(props.data)
        }
        const edit = (e) => {
            localStorage.setItem("editSensorId", props.data.id);
            history.push("/Home/Sensor/add-update-sensor");
        }
        const copyAndedit = (e) => {
            localStorage.setItem("copyAndEditSensorId", props.data.id);
            history.push("/Home/Sensor/add-update-sensor");
        }

        return (
            <ul className="trigger-div mb-0 px-0">
                {/* {((parseInt(role) === 1) && (props.data.isDefault)) || ((props.data.organizationId === organizationId) && (parseInt(role) === 2))
                    || (props.data.createdBy === userId)
                    ? <i className="mx-4 fas fa-pencil-alt color-orange pointer" onClick={edit} data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" ></i>
                    : <i className="mx-4 fas fa-pencil-alt color-orange pointer sensor-edit" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" disabled></i>} */}
                    <i className="mx-4 fas fa-pencil-alt color-orange pointer" onClick={edit} data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" ></i>
                <i className="fas fa-copy color-orange pointer" data-bs-toggle="tooltip" data-bs-placement="top" title="Copy" onClick={copyAndedit}></i>
                <i className="mx-4 fas fa-trash color-orange pointer" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" onClick={status}></i>
            </ul>
        );
    }

    const setOpen = (data) => {
        handleShow();
        setSensorName(data.name);
        setSensorId(data.id);
        setSensorStatus(data.status)
    };
    const deleteFunction = (id) => {
        const data = { sensorId: id }

        axios.post("/Sensors/deleteSensor", data).then((res) => {
            notify(messages.ToastSuccess, messages.sensorDeleteSuccessfully);
            getSensorList()
            handleClose()
        })
    }
    function GridActionsElement(props) {
        return (
            <div>
                {props.data.name}
                <span className="elementDefault">{((parseInt(role) === 1) && (props.data.isDefault)) || ((props.data.organizationId === organizationId) && (parseInt(role) === 2))
                    || (props.data.createdBy === userId) ? "" : "Default"}</span>
            </div>
        );
    }
    // const staticCellStyle = { color: "#FF7538" };

    const [rowData, setRowData] = useState([]);

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "Name",
            field: "name",
            headerClass: "ag-custom-header",
            cellRendererFramework: GridActionsElement,
            maxWidth: 800,
            minWidth: 120,
        },
        {
            headerName: "Label",
            field: "label",
            headerClass: "ag-custom-header",
            minWidth: 150
        },

        {
            headerName: "Actions",
            field: "Actions",
            headerClass: "ag-custom-header",
            cellRendererFramework: GridActions,
            pinned: "right",
            maxWidth: 150,
            minWidth: 100,
        },
    ]);
    const containerStyle = {
        boxSizing: "border-box",
        height: rowData.length * messages.GridRowheight + messages.GridHeaderheight,
        minHeight: 150,
        width: "calc(100%)",
    };
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setSkip(pageNumber * itemsCountPerPage.value - itemsCountPerPage.value);
    };

    const getSensorList = () => {
        const url = "/Sensors/getSensorList";
        setSpinner(true);
        axios.post(url, {
            forAlarms: forAlarms,
            skip: skip,
            limit: itemsCountPerPage.value,
            searchContent: searchContent,
            fields: fields,
            showTotalCount: showTotalCount,
            skipDrager:true
        }).then((res) => {
            setSpinner(false);
            if (res.data) {
                let rows = [];
                let data = res.data.items;
                for (let i in data) {
                    let sensorData = {};
                    sensorData.label = data[i].label;
                    sensorData.name = data[i].name;
                    sensorData.id = data[i].id;
                    sensorData.status = data[i].status;
                    sensorData.isDefault = data[i].isDefault;
                    sensorData.organizationId = data[i].organizationId;
                    sensorData.createdBy = data[i].createdBy;
                    rows.push(sensorData);
                }
                setRowData(rows);
            }
            setTotalCount(res.data.totalCount)
        }).catch((error) => {
            console.log(error);
            setSpinner(false);
        })
    }
    useEffect(() => {
        localStorage.removeItem('editSensorId')
        localStorage.removeItem('copyAndEditSensorId')
    }, [])
    useEffect(() => {
        getSensorList();
    }, [skip, itemsCountPerPage, status])

    const restoreFunction = () => {
        setSpinner(true);
        axios.post("/Sensors/restoreDefaultSensors").then((res) => {
            if (res) {
                console.log(res);
                getSensorList()
                setSpinner(false);
            }
        }).catch((error) => {
            console.log(error);
            setSpinner(false);
        })
    }
    return (
        <Container fluid className="px-0 body-component">
            <ReactTitle title="Home-Sensors" />
            <ActionConfirmationPopup
                handleClose={handleClose}
                handleShow={handleShow}
                setShow={setShow}
                show={show}
                message={(show && sensorStatus === 1 && messages.deleteSensorMessage + " " + `"${sensorName}"` + " sensor?")}
                action={show && sensorStatus === 1 && messages.delete}
                deactivateFunction={() => deleteFunction(sensorId)}
            />
            <div className="container-fluid mt-3">
                <div className="row">
                    <p className="font-bold mb-1"> Sensors</p>
                </div>
                <hr />
                <div className="row my-5">
                    <GridFilter
                        role={role}
                        entries={true}
                        search={true}
                        restore={true}
                        restoreFunction={restoreFunction}
                        itemsCountPerPage={itemsCountPerPage}
                        setItemsCountPerPage={(e) => {
                            setSpinner(true)
                            setSkip(0)
                            setItemsCountPerPage(e);
                            setSpinner(false)
                        }}
                        setStatus={setStatus}
                        status={status}
                        addBtn={true}
                        addBtnPath={"/Home/Sensor/add-update-sensor"}
                        ajaxCall={getSensorList}
                        setSearchContent={(e) => {
                            setSpinner(true)
                            setSkip(0)
                            setSearchContent(e);
                            setSpinner(false)
                        }}
                        tooltipTitle={"Sensor"}
                        setSkip={setSkip}
                        setActivePage={setActivePage}
                    />
                </div>
                <div className="container-fluid grid-list-div mb-3 ">
                    <div className=" organization-table">
                        <div className="ag-theme-alpine application-grid">
                            {spinner ? (<Spinner />) :
                                (<ReactGrid
                                    id="requestConsultGrid"
                                    gridId="Request-consult-List"
                                    containerStyle={containerStyle}
                                    columnDefs={columnDefs}
                                    rowData={rowData}
                                />)}
                        </div>
                        {totalCount > 10 ? (
                            <div className="row mb-5">
                                <div className="col-sm-6 font-bold mt-2">
                                    <ShowEntries
                                        totalCount={totalCount}
                                        itemsCountPerPage={itemsCountPerPage.value}
                                        activePage={activePage}
                                    />
                                </div>
                                <div className="col-md-6 pagination-component">
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={itemsCountPerPage.value}
                                        totalItemsCount={totalCount}
                                        pageRangeDisplayed={2}
                                        onChange={handlePageChange}
                                        itemClass="pagination-item"
                                        linkClass="pagination-link"
                                        prevPageText="Previous"
                                        nextPageText="Next"
                                    />
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </Container>
    );
}
export default SensorList;