import { React, useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Container, Form, Row, Col } from 'react-bootstrap';
import profilePicture from "../../images/man.png";
import { notify, isMatches, focusById, isEmail, uploadFile } from '../utils/utility'
import '../../style/global.css'
import '../../style/style.css'
import { useInputHook } from "../custom/custom-input-hook";
import axios from "axios";
import { messages } from '../utils/constants';
import { ReactTitle } from "react-meta-tags";
const _ = require('lodash');

const AddUser = ({ data, Profile }) => {
    const userLoggedInId = localStorage.getItem("userId")
    const history = useHistory();
    const invalidData = {
        name: false,
        email: false,
        mobileNumber: false,
        street: false,
        zipCode: false
    };
    const userRole = localStorage.getItem("role")
    const [invalid, setInvalid] = useState(invalidData);
    const [street, setStreet] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [action, setAction] = useState("add");
    const userId = localStorage.getItem('editUserId') || "";
    const [country, setCountry] = useState("");
    const [role, setRole] = useState(userRole === "1" ? "1" : messages.role[0].id);
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [countryCodeList, setCountryCodeList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [savedState, setSavedState] = useState();
    const [savedCity, setSavedCity] = useState();
    const [src, setSrc] = useState(profilePicture);
    const [imageName, setImageName] = useState(messages.uploadFileInitialState);    
   
    useEffect(() => {
        if (userId || Profile) {
            setAction("edit");
            getUserData();
        } else {
            getCountryList()
        }
    }, [])


    const validator = () => {
        let invalid = {
            name: false,
            email: false,
            mobileNumber: false,
            street: false,
            zipCode: false
        };
        var flag = false;
        /*Validations*/
        if (!isMatches(name, messages.OnlyAlphaNumeric)) {
            flag = invalid.name = true;
            focusById('name');
            notify(messages.ToastError, messages.InvalidName)
        } else if (!isEmail(email)) {
            flag = invalid.email = true;
            focusById('email');
            notify(messages.ToastError, messages.InvalidEmail);
        } else if (mobileNumber && !isMatches(mobileNumber, messages.RegexAllowForPhoneNumber)) {
            flag = invalid.mobileNumber = true;
            focusById('mobileNumber');
            notify(messages.ToastError, messages.InvalidMobileNumber)
        } else if (!isMatches(street, messages.RegexAlphaNumericSpecialChar)) {
            flag = invalid.street = true;
            focusById('street');
            notify(messages.ToastError, messages.InvalidStreetName)
        } else if (!isMatches(zipCode, messages.RegexAlphaNumericSpecialChar)) {
            flag = invalid.zipCode = true;
            focusById('zipCode');
            notify(messages.ToastError, messages.InvalidZipCode)
        }
        setInvalid(invalid)
        return flag ? false : true;
    }

    function submitHandler(e) {
        e.preventDefault(e);
        let data = {
            // accessToken: accessToken ? accessToken : "",
            name: name ? name.trim() : "",
            email: email ? email.trim() : "",
            mobile: mobileNumber ? mobileNumber.trim() : "",
            street: street ? street.trim() : "",
            country: country ? country.name : "",
            state: state ? state.name : "",
            city: city ? city.name : "",
            zipCode: zipCode ? zipCode.trim() : "",
            role: role,
            id: Profile ? userLoggedInId : userId ? userId : "",
            profileSrc: imageName ? imageName : {}
        }
        let isValid = validator();
        if (isValid) {
            if (action === "add") {
                axios.post('/signup', data)
                    .then(function (response) {
                        // handle success
                        let data = response.data ? response.data : {};
                        if (data.signup) {
                            notify(messages.ToastSuccess, "User added successfully.");
                            history.push("/Home/User");
                        }
                        else if (data.errorCode) {
                            if (data.errorCode === 406) {
                                notify(messages.ToastError, messages.UserAlreadyExist);
                            }else if (data.errorCode === 407) {
                                notify(messages.ToastError, messages.UserAlreadyExistAnotherOrg);
                            }
                        }
                    })
                    .catch(function (error) {

                    })
            } else {
                axios.post('/userUpdate', data)
                    .then(function (response) {
                        // handle success
                        let data = response.data ? response.data : {};
                        if (data.errorCode) {
                            if (data.errorCode === 406) {
                                notify(messages.ToastError, messages.UserAlreadyExist);
                            }else if (data.errorCode === 407) {
                                notify(messages.ToastError, messages.UserAlreadyExistAnotherOrg);
                            }
                        } else if (data) {
                            notify(messages.ToastSuccess, Profile?messages.UserProfileUpdated:messages.userAccountUpdated);
                            if(!Profile)
                            history.push("/Home/User")
                            if ((Profile === true) || userLoggedInId === userId) {
                                localStorage.setItem('profileSrc', "/upload/userProfile/" + imageName.serverName);
                                localStorage.setItem('loggedInUserName', name );
                                window.fetchLatestProfile()
                            }
                        }
                    })
                    .catch(function (error) {
                    })
            }
        }
    }

    useEffect(() => {
        if (imageName.serverName) {
            let src = "/upload/userProfile/" + imageName.serverName;
            setSrc(src)
        }
    }, [imageName])

    const previewFile = (e) => {
        if (e.target.files && e.target.files.length === 1) {
            let file = e.target.files[0], formData = new FormData();
            if (!file.type.startsWith("image/")) {
                // error
                notify(messages.ToastError, messages.ValidProfile);
                return;
            }
            formData.append('fileName', file.name);
            formData.append('attachmentType', 'userProfile');
            formData.append('fileType', file.type);
            formData.append('size', file.size);
            formData.append('file', file);
            uploadFile(formData, setImageName, messages.ProfileUploadErr);
        }
    }

    const handleSelectCountry = (event) => {
        setCountry({ value: event.target.value, name: event.target.options[event.target.selectedIndex].text });
    }
    const handleSelectState = (event) => {
        setState({ value: event.target.value, name: event.target.options[event.target.selectedIndex].text });
    }
    const handleSelectCity = (event) => {
        setCity({ value: event.target.value, name: event.target.options[event.target.selectedIndex].text });
    }
    const handleSelectRole = (event) => {
        setRole(event.target.value);
    }

    useEffect(() => {
        setState("")
        setSavedState("");
        if (country)
            getStateList()
    }, [country])
    useEffect(() => {
        setCity("");
        setSavedCity("");
        if (state)
            getCityList()
    }, [state])

    const getUserData = () => {
        axios.post('/getUserDetail', { userId: Profile ? userLoggedInId : userId ? userId : "" })
            .then(function (response) {
                // handle success
                let data = response.data ? response.data : {};
                if (data) {
                    const { name = "", email = "", mobile = "", street = "", zipCode = "", country = "", state = "", city = "", profileSrc = {}, role } = data;
                    setName(name);
                    setEmail(email);
                    setMobileNumber(mobile);
                    setStreet(street);
                    setZipCode(zipCode);
                    getCountryList(country);
                    setSavedState(state);
                    setSavedCity(city);
                    setImageName(profileSrc);
                    setRole(role);
                }
            })
            .catch(function (error) {
            })
    }
    const getCountryList = (savedCountry) => {
        axios.post("/getCountryList", {
            // accessToken: accessToken
        }).then((response) => {
            // handle success
            let data = response.data ? response.data : {};
            if (data.errorCode) {
                notify(messages.ToastError, "server issue");
            }
            else {
                let countryCodeList = []
                for (let i in data) {
                    let code = {
                        id: i,
                        label: data[i].phone_code,
                        value: data[i].phone_code
                    }
                    countryCodeList.push(code)
                    data[i].label = data[i].name;
                    data[i].value = data[i].uniqueId;
                }
                setCountryCodeList(countryCodeList)
                setCountryList(data)
                /*country*/
                if (savedCountry) {
                    let selectCountry = {};
                    selectCountry = _.find(data, function (inst) {
                        return inst["label"] === savedCountry
                    }.bind(this));
                    setCountry(selectCountry);
                }else{
                    setCountry({ value: data[0].value, name: data[0].name })
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    const getStateList = () => {
        axios.post('/getStateList', { countryUniqueId: country.value })
            .then(function (response) {
                // handle success
                let data = response.data ? response.data : {};
                if (data.errorCode) {
                    notify(messages.ToastError, "server issue");
                }
                else {
                    for (let i in data) {
                        data[i].label = data[i].name;
                        data[i].value = data[i].uniqueId;
                    }
                    setStateList(data)
                    /*state*/
                    if (savedState) {
                        let selectState = {};
                        selectState = _.find(data, function (inst) {
                            return inst["label"] === savedState
                        }.bind(this));
                        setState(selectState);
                    }else{
                        setState({ value: data[0].value, name: data[0].name })
                    }
                }
            })
            .catch(function (error) {
            })
    }
    const getCityList = () => {
        axios.post('/getCityList', { stateUniqueId: state.value })
            .then(function (response) {
                // handle success
                let data = response.data ? response.data : {};
                if (data.errorCode) {
                    notify(messages.ToastError, "server issue");
                }
                else {
                    for (let i in data) {
                        data[i].label = data[i].name;
                        data[i].value = data[i].uniqueId;
                    }
                    setCityList(data)
                    /*city*/
                    if (savedCity) {
                        let selectCity = {};
                        selectCity = _.find(data, function (inst) {
                            return inst["label"] === savedCity
                        }.bind(this));
                        setCity(selectCity);
                    }else{
                        setCity({ value: data[0].value, name: data[0].name })
                    }
                }
            })
            .catch(function (error) {
            })
    }
    let disable = !street || !country || !state || !city || !zipCode || !name || !email || !role;
    return (
        <Container className="body-component">
            <ReactTitle title={Profile ? "Home-Profile" : action === "add" ? "Home-Add-User" : "Home-Edit-User"} />
            <Row className="border-bottom">
                <div className="add-update-heading">{Profile ? messages.Profile : (action === "add" ? messages.AddUser : messages.EditUser)}</div>
            </Row>
            <Row className="add-update-right-wrapper">
                <Form className="row add-user-form-root" onSubmit={submitHandler}>
                    <div className="mt-3 form-inputs-div">
                        <Row>
                            <h5>{messages.BasicInfo}</h5>
                            <Row>
                                <Col lg={3} className="add-user-profile-icon-div">
                                    <div className="profile-icon-add-user avtarPhoto">
                                        <input type='file' id="imgInp" accept="image/*" onChange={previewFile} onClick={(event) => { event.target.value = null }} />
                                        <label htmlFor="imgInp">
                                            <img
                                                src={src}
                                                alt="preview"
                                                width={100}
                                                height={100}
                                                className="rounded-circle profilePic"
                                                id="blah"
                                                title={imageName.actualName}
                                                htmlFor="imgInp" />
                                            <i className="fas fa-pencil-alt editavtar"></i></label>
                                    </div>
                                </Col>
                                <Col lg={9}>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Label>{messages.Name}*</Form.Label>
                                                <Form.Control type="text" placeholder="" value={name} className={invalid.name ? " is-invalid" : ""} onChange={(e) => { setName(e.target.value) }} maxLength={255} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3" controlId="email">
                                                <Form.Label>{messages.EmailTitleCase}*</Form.Label>
                                                <Form.Control type="text" placeholder="" value={email} className={invalid.email ? " is-invalid" : ""} onChange={(e) => { setEmail(e.target.value) }} maxLength={255} disabled={action === "edit" || Profile ? true : false} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mb-3" controlId="mobileNumber">
                                                <Form.Label>{messages.MobileNumber}</Form.Label>
                                                <Form.Control type="text" placeholder="" value={mobileNumber} className={invalid.mobileNumber ? " is-invalid" : ""} onChange={(e) => { setMobileNumber(e.target.value) }} minLength={8} maxLength={15} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            {userRole === "1" || Profile ? null : <Form.Group className="mb-3" controlId="role">
                                                <Form.Label>{messages.RoleLabel}*</Form.Label>
                                                <Form.Select aria-label="Default select example" id="role" value={role} onChange={handleSelectRole} >
                                                    {messages.role.map((e, key) => {
                                                        return <option key={key} value={e.id}>{e.name}</option>;
                                                    })}
                                                </Form.Select>
                                            </Form.Group>}
                                        </Col>
                                    </Row></Col>
                            </Row>
                        </Row>
                    </div>
                    <div className="mt-5 form-inputs-div">
                        <Row>
                            <h5>{messages.Address}</h5>
                            <Col lg={4}>
                                <Form.Group className="mb-3" controlId="street">
                                    <Form.Label>{messages.Street}*</Form.Label>
                                    <Form.Control type="text" placeholder="" className={invalid.street ? " is-invalid" : ""} value={street} onChange={(e) => { setStreet(e.target.value) }} maxLength={500} />
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3" controlId="country">
                                    <Form.Label>{messages.Country}*</Form.Label>
                                    <Form.Select aria-label="Default select example" value={country ? country.value : ""} onChange={handleSelectCountry} >
                                        {countryList.map((e, key) => {
                                            return <option key={key} value={e.value}>{e.label}</option>;
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3" controlId="State">
                                    <Form.Label>{messages.State}*</Form.Label>
                                    <Form.Select aria-label="Default select example" value={state ? state.value : ""} onChange={handleSelectState} >
                                        {stateList.length ? stateList.map((e, key) => {
                                            return <option key={key} value={e.value}>{e.label}</option>;
                                        }) : <option value="" disabled>State are not available</option>}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3" controlId="City">
                                    <Form.Label>{messages.City}*</Form.Label>
                                    <Form.Select aria-label="Default select example" value={city ? city.value : ""} onChange={handleSelectCity} >
                                        {cityList.length ? cityList.map((e, key) => {
                                            return <option key={key} value={e.value}>{e.label}</option>;
                                        }) : <option value="" disabled>City are not available</option>}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3" controlId="zipCode">
                                    <Form.Label>{messages.ZipCode}*</Form.Label>
                                    <Form.Control type="text" placeholder="" className={invalid.zipCode ? " is-invalid" : ""} value={zipCode} onChange={(e) => { setZipCode(e.target.value) }} minLength={5} maxLength={30} />
                                </Form.Group>
                            </Col>

                        </Row>
                    </div>
                </Form >
            </Row>
            <Row className="px-3 pe-2">
                <Col lg={12} md={12} sm={12} xs={12} className="save-cancel-exit-btn">
                    <button
                        type="button"
                        className="button-custom saveBtn"
                        variant="primary"
                        onClick={() =>Profile? history.goBack():history.push("/Home/User")}
                    >
                        {messages.cancel}
                    </button>
                    <button
                        className="button-custom saveBtn submitSaveBtn"
                        variant="primary"
                        type="submit"
                        disabled={disable}
                        onClick={submitHandler}
                    >
                        {messages.Save}
                    </button>
                </Col>
            </Row>
        </Container >
    )
}

export default AddUser
