import React, { useEffect, useState } from 'react'
import { Row, Col, Container, Nav } from 'react-bootstrap'
import { messages } from "../../utils/constants";
import { Link } from 'react-router-dom';
import axios from 'axios'

const TroubleshootingInfo = () => {
    const [type, setType] = useState(6);
    const [troubleShooting, setTroubleShooting] = useState([{}]);

    useEffect(() => {
        getSupportDocuments();
    }, [type])

    const getSupportDocuments = () => {
        axios.post("/documents/getDocumentList", { type: type }).then((res) => {
            if (res.data) {

                let rows = [];
                let data = res.data;
                for (let i in data) {
                    let ManualsData = {};
                    ManualsData.issue = data[i].issue;
                    ManualsData.resolution = data[i].resolution;
                    rows.push(ManualsData);
                }
                setTroubleShooting(rows)
            }
        })
    }


    const troubleShootingData = troubleShooting.map((mn) => {
        return (
            <li><h6><strong>{messages.Issue}</strong>{mn.issue}</h6>
                <p><strong>{messages.Resolution}</strong>{mn.resolution}</p>
            </li>
        )

    })

    return (
        <>
            <Container className="manuals-root">
                <Row>
                    <h6 className="text-center manual-heading">{messages.TroubleshootingInformation}</h6>
                </Row>
                <hr />
                <Row className="mt-4 manual-data troubleshooting-data">
                    <ul className="reference-links">
                        {troubleShootingData}
                    </ul>
                </Row>
            </Container>

        </>
    )
}

export default TroubleshootingInfo
