import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
// import { sideMenuSuperAdmin as sideMenu } from "../main-wrapper/side-menus/side-menus-super-admin";
import ReactGrid from "../grid/reactGrid";
import GridFilter from "../grid/grid-filter";
import ShowEntries from "../utils/show-entries";
import Pagination from "react-js-pagination";
import "../../style/style.css"
import { messages } from "../utils/constants";
import { toDefaultDateFormat } from '../utils/utility';
import ActionConfirmationPopup from "../shared/action-confirmation-popup";
import axios from "axios";
import { ReactTitle } from "react-meta-tags";

function OrganizationBody({ state }) {
    const [status, setStatus] = useState(1);
    const [searchContent, setSearchContent] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(
        messages.GridRowCountOptions[0]
    );
    const [totalCount, setTotalCount] = useState();
    const [skip, setSkip] = useState(0);
    const [show, setShow] = useState(false);
    const [organizationName, setOrganizationName] = useState("");
    const [organizationId, setOrganizationId] = useState("");
    const [organizationStatus, setOrganizationStatus] = useState("");

    useEffect(()=>{
        localStorage.removeItem("orgId");
    },[])
    // const accessToken = localStorage.getItem('accessToken')
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };
    function GridActions(props) {
        const status = () => {
            setOpen(props.data)
        }
        return (
            <ul className="trigger-div mb-0 px-0">
                <Link to="/Home/Organizations/add-update-organization" onClick={() => orgData(props.data)}><i className="mx-4 fas fa-pencil-alt color-orange" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"></i></Link>
                <i className={props.data.status === 1 ? "fas fa-user-slash color-orange pointer" : "fas fa-user color-orange"} data-bs-toggle="tooltip" data-bs-placement="top" title={props.data.status === 1 ? "Deactive" : "Active"} onClick={status}></i>
            </ul>
        );
    }
    const orgData = (data) => {
        localStorage.setItem("orgId", data.id);
    }
    const setOpen = (data) => {
        handleShow();
        setOrganizationName(data.name);
        setOrganizationId(data.id);
        setOrganizationStatus(data.status)
    };
    const activeDeactivateFunction = (id) => {
        let status = organizationStatus === 1 ? 2 : 1;
        const data = { id: id, status: status }
        axios.post("/Organizations/organizationActiveDeactivate", data).then((res) => {
            getOrganizationList()
            handleClose()
        })
    }
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "Organization",
            field: "name",
            headerClass: "ag-custom-header",
            maxWidth: 270,
            minWidth: 150,
        },
        {
            headerName: "Contact Name",
            field: "contactName",
            headerClass: "ag-custom-header",
            maxWidth: 270,
            minWidth: 120,
        },
        {
            headerName: "Email",
            field: "contactEmail",
            headerClass: "ag-custom-header",
            minWidth: 180,
        },
        {
            headerName: "Mobile Number",
            field: "contactMobile",
            headerClass: "ag-custom-header",
            maxWidth: 250,
            minWidth: 150,
        },
        {
            headerName: "Expiry Date",
            field: "expiryDate",
            headerClass: "ag-custom-header",
            maxWidth: 200,
            minWidth: 100,
        },
        {
            headerName: "Actions",
            field: "Actions",
            headerClass: "ag-custom-header",
            cellRendererFramework: GridActions,
            pinned: "right",
            maxWidth: 100,
            minWidth: 100,
        },
    ]);
    const getOrganizationList = () => {
        const url = "/Organizations/getOrganizationList";
        axios.post(url, {
            //accessToken: accessToken,
            skip: skip,
            limit: itemsCountPerPage.value,
            searchContent: searchContent,
            status: parseInt(status),
        }).then((res) => {
            if (res.data.items) {
                let rows = [];
                let data = res.data.items;
                for (let i in data) {
                    let item = {};
                    item.name = data[i].name;
                    item.contactName = data[i].contactName;
                    item.contactEmail = data[i].contactEmail;
                    item.contactMobile = data[i].contactMobile;
                    item.id = data[i].id;
                    item.status = data[i].status;
                    item.expiryDate = toDefaultDateFormat(data[i].expiryDate, messages.momentDateFormat);
                    rows.push(item);
                }
                setRowData(rows);
            }

            // setTotalCount(res.data.items.totalCount)

            setTotalCount(res.data.totalCount)
        }).catch((error) => {
        })
    }
    useEffect(() => {
        getOrganizationList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skip, itemsCountPerPage, status])



    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setSkip((pageNumber * itemsCountPerPage.value) - itemsCountPerPage.value)
    };
    const containerStyle = {
        boxSizing: "border-box",
        height: rowData.length * messages.GridRowheight + messages.GridHeaderheight,
        minHeight: 150,
        width: "calc(100%)",
    };
    const statusOptions = ["Activated", "Deactivated"];

    return (
        <Container fluid className="px-0 body-component">
            <ReactTitle title="Home-Organizations" />
            <ActionConfirmationPopup
                handleClose={handleClose}
                handleShow={handleShow}
                setShow={setShow}
                show={show}
                message={(show && organizationStatus === 1 ? messages.deactivateOrganizationMessage : messages.activateOrganizationMessage) + " " + organizationName + " ?"}
                action={show && organizationStatus === 1 ? messages.deactivate : messages.Activate}
                deactivateFunction={() => activeDeactivateFunction(organizationId)}
            />

            <div className="container-fluid mt-3">
                <div className="row">
                    <p className="font-bold mb-1"> Organizations</p>
                </div>
                <hr />
                <div className="row my-5">
                    <GridFilter
                        entries={true}
                        search={true}
                        ajaxCall={getOrganizationList}
                        setSearchContent={(e) => {
                            setSkip(0)
                            setSearchContent(e);
                        }}
                        itemsCountPerPage={itemsCountPerPage}
                        setItemsCountPerPage={setItemsCountPerPage}
                        setStatus={setStatus}
                        status={status}
                        addBtn={true}
                        addBtnPath={"/Home/Organizations/add-update-organization"}
                        statusOptions={statusOptions}
                        tooltipTitle={"Organization"}
                        setSkip={setSkip}
                        setActivePage={setActivePage}
                    />
                </div>
                <div className="container-fluid organization-list-div mb-3 ">
                    <div className=" organization-table">
                        <div className="ag-theme-alpine application-grid">
                            <ReactGrid
                                id="requestConsultGrid"
                                gridId="Request-consult-List"
                                containerStyle={containerStyle}
                                columnDefs={columnDefs}
                                rowData={rowData}
                            />
                        </div>
                        {totalCount > 10 ? <div className="row mb-5">
                            <div className="col-sm-6 font-bold mt-2">
                                <ShowEntries
                                    totalCount={totalCount}
                                    itemsCountPerPage={itemsCountPerPage.value}
                                    activePage={activePage}
                                />
                            </div>
                            <div className="col-md-6 pagination-component">
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={itemsCountPerPage.value}
                                    totalItemsCount={totalCount}
                                    pageRangeDisplayed={2}
                                    onChange={handlePageChange}
                                    itemClass="pagination-item"
                                    linkClass="pagination-link"
                                    prevPageText="Previous"
                                    nextPageText="Next"
                                />
                            </div>
                        </div> : ''}
                    </div>
                </div>
            </div>
        </Container>
    );
}
export default OrganizationBody;
