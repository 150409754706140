import { React, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import ReactGrid from "../grid/reactGrid";
import GridFilter from "../grid/grid-filter";
import ShowEntries from "../utils/show-entries";
import Pagination from "react-js-pagination";
import "../../style/style.css";
import { messages } from "../utils/constants";
import ActionConfirmationPopup from "../shared/action-confirmation-popup";
import axios from "axios";
import { ReactTitle } from "react-meta-tags";
function UserList({ state, data }) {
  const [status, setStatus] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [searchContent, setSearchContent] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(
    messages.GridRowCountOptions[0]
  );
  const [totalCount, setTotalCount] = useState();
  const [showTotalCount, setShowTotalCount] = useState(true);
  const [skip, setSkip] = useState(0);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem("editUserId");
  }, [])

  const statusOptions = ["Activated", "Suspended"];
  useEffect(() => {
    usersList();
  }, [skip, itemsCountPerPage, status]);

  const setOpen = (data) => {
    handleShow();
    setUserName(data.User);
    setUserId(data.id);
    setUserStatus(data.status)
  };
  function GridActions(props) {
    const edit = (e) => {
      localStorage.setItem("editUserId", props.data.id);
      history.push("/Home/User/add-user");
    }
    const status = () => {
      setOpen(props.data);
    }
    return (
      <ul className="trigger-div mb-0 px-0">
        <i
          className="mx-4 fas fa-pencil-alt color-orange pointer"
          onClick={edit} data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" ></i>
        <i className={props.data.status === 1 ? "fas fa-user-slash color-orange pointer" : "fas fa-user color-orange pointer"} data-bs-toggle="tooltip" data-bs-placement="top" title={props.data.status === 1 ? "Suspend" : "Activate"} onClick={status}></i>
      </ul>
    );
  }

  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "User",
      field: "User",
      headerClass: "ag-custom-header",
      maxWidth: 370,
      minWidth: 150,
    },
    {
      headerName: "Email",
      field: "Email",
      headerClass: "ag-custom-header",
      minWidth: 180,
    },
    {
      headerName: "Mobile Number",
      field: "MobileNumber",
      headerClass: "ag-custom-header",
      maxWidth: 350,
      minWidth: 150,
    },
    {
      headerName: "Role",
      field: "role",
      headerClass: "ag-custom-header",
      maxWidth: 350,
      minWidth: 150,
    },
    {
      headerName: "Actions",
      field: "Actions",
      headerClass: "ag-custom-header",
      cellRendererFramework: GridActions,
      pinned: "right",
      maxWidth: 100,
      minWidth: 100,
    },
  ]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setSkip((pageNumber * itemsCountPerPage.value) - itemsCountPerPage.value)
  };
  const activeDeactivateFunction = (id) => {
    let status = userStatus === 1 ? 2 : 1;
    const data = { id: id, status: status };
    axios.post("/userActiveDeactivate", data).then((res) => {
      usersList();
      handleClose();
    });
  };
  const containerStyle = {
    boxSizing: "border-box",
    height: rowData.length * messages.GridRowheight + messages.GridHeaderheight,
    minHeight: 150,
    width: "calc(100%)",
  };
  const usersList = () => {
    const url = "/UserAccounts/getUserList";
    setSpinner(true);
    axios
      .get(url, {
        params: {
          skip: skip,
          searchContent: searchContent,
          limit: itemsCountPerPage.value,
          status: parseInt(status),
          organizationId: localStorage.getItem("organizationId"),
          showTotalCount: showTotalCount
        },
      })
      .then(function (response) {
        setSpinner(false);
        let rows = [];
        let data = response.data.items;
        for (let i in data) {
          let role = messages.AllRole.find(inst => inst.id === data[i].role.toString())
          let item = {}
          item.User = data[i].name;
          item.Email = data[i].email;
          item.id = data[i].id;
          item.MobileNumber = data[i].mobile;
          item.status = data[i].status;
          item.role = role.name || ""
          rows.push(item);
        }
        setRowData(rows);
        setTotalCount(response.data.totalCount)
      }).catch((error) => {
        console.log(error);
        setSpinner(false);
      });
  };



  return (
    <Container fluid className="px-0 body-component">
      <ReactTitle title="Home-Users" />
      <ActionConfirmationPopup
        handleClose={handleClose}
        handleShow={handleShow}
        setShow={setShow}
        show={show}
        message={(show && userStatus === 1 ? messages.suspendUserMessage : messages.activateOrganizationMessage) + ` " ${userName}" ?`}
        action={show && userStatus === 1 ? messages.suspend : messages.Activate}
        deactivateFunction={() => activeDeactivateFunction(userId)}
      />

      <div className="container-fluid mt-3">
        <div className="row">
          <p className="font-bold mb-1"> Users</p>
        </div>
        <hr />
        <div className="row my-5">
          <GridFilter
            entries={true}
            search={true}
            ajaxCall={usersList}
            setSearchContent={(e) => {
              setSpinner(true)
              setSkip(0)
              setSearchContent(e);
              setSpinner(false)

            }}
            itemsCountPerPage={itemsCountPerPage}
            setItemsCountPerPage={(e) => {
              setSpinner(true)
              setSkip(0)
              setItemsCountPerPage(e);
              setActivePage(1);
              setSpinner(false);
            }}
            setStatus={(e) => {
              setStatus(e);
            }}
            addBtn={true}
            addBtnPath={"/Home/User/add-user"}
            statusOptions={statusOptions}
            tooltipTitle={"User"}
            setSkip={setSkip}
            setActivePage={setActivePage}
          />
        </div>
        <div className="container-fluid organization-list-div mb-3 ">
          <div className=" organization-table">
            <div className="ag-theme-alpine application-grid">
              <ReactGrid
                id="requestConsultGrid"
                gridId="Request-consult-List"
                containerStyle={containerStyle}
                columnDefs={columnDefs}
                rowData={rowData}
              />
            </div>
            {totalCount > 10 ? (<div className="row mb-5">
              <div className="col-sm-6 font-bold mt-2">
                <ShowEntries
                  totalCount={totalCount}
                  itemsCountPerPage={itemsCountPerPage.value}
                  activePage={activePage}
                />
              </div>
              <div className="col-md-6 pagination-component">
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={itemsCountPerPage.value}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={2}
                  onChange={handlePageChange}
                  itemClass="pagination-item"
                  linkClass="pagination-link"
                  prevPageText="Previous"
                  nextPageText="Next"
                />
              </div>
            </div>) : ''}
          </div>
        </div>
      </div>
    </Container>
  );
}
export default UserList;
