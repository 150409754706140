import { Modal } from 'react-bootstrap';
import { messages } from '../utils/constants'
import { useState } from 'react';
import axios from "axios"
import { useHistory } from "react-router-dom";
import { notify } from '../utils/utility'



const ForgetPassword = ({ handleClose, handleShow, show }) => {
    const [email, setEmail] = useState();
    const [spinner, setSpinner] = useState(false);
    const [msg, setMsg] = useState(false)
    const [emailErr, setEmailErr] = useState({});
    const history = useHistory();

    const handleSubmit = () => {
        const isValid = formValidation();
        if (isValid) {
            axios
                .post("/resetPassword", { email })
                .then((res) => {
                    setMsg(true);
                    if (res.data.errorCode) {
                    }
                    else {
                        notify(messages.ToastSuccess,"Email sent successfully");
                        setSpinner(false)
                        setTimeout(() => {
                            history.push("/")
                        }, 1000);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    notify(messages.ToastError,"User Does Not Exist");
                    setSpinner(false)
                });
        }
        else {
            notify(messages.ToastError,messages.invalidEmail);
        }
    }
    const formValidation = () => {
        const emailErr = {};
        let isValid = true;
        if (email.trim().length < 6) {
            emailErr.emailShort = messages.invalidEmail;
            isValid = false;
        }
        setEmailErr(emailErr);
        return isValid;
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <div style={{ paddingLeft: "2rem", paddingRight: "2rem", paddingTop: "1rem", paddingBottom: "1rem" }}>
                    <Modal.Header closeButton className="pt-0 px-0 Heading-for-modal"><p>{messages.ForgotPassword}</p>
                    </Modal.Header>
                    <Modal.Body className="px-0"><div>
                        {msg ? <div><p>You will receive an email to reset your password if your account exists in the application.</p></div> : ""}
                        {!msg ? <div><input
                            className="input-basic modal-input "
                            type="email"
                            placeholder="EMAIL"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        </div>
                            : ""}
                    </div></Modal.Body>
                    {!msg ? <Modal.Footer className="px-0">
                        <div className="submitAndCloseBtn">
                            <button
                                className={`button-custom submitBtn forgot-password-button pointer ${!email && "disabled"}`}
                                variant="primary"
                                onClick={handleSubmit}
                                disabled={(!email) && true}
                            >
                                Submit
                            </button>
                            <button
                                className="button-custom closeBtn"
                                variant="primary"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </Modal.Footer> : ""}
                </div>
            </Modal>
        </>

    )
}
export default ForgetPassword;