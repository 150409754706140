import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form} from "react-bootstrap";
import Gadgets from "../../images/gadgets.png";
import '../../style/style.css'
import Logo from "../../images/logo.png";
import { useInputHook } from "../custom/custom-input-hook";
import { messages } from "../utils/constants";
import { notify, isMatches, focusById, isEmail } from '../utils/utility'
import axios from "axios";

const ContactUs = () => {
  const invalidData = {
    name: false,
    email: false,
    message: false
  }
  const [invalid, setInvalid] = useState(invalidData);
  const [name, setName] = useInputHook("");
  const [email, setEmail] = useInputHook("");
  const [message, setMessage] = useInputHook("");
  const submitHandler = (e) => {
    e.preventDefault(e);
    let isValid = validator();
    var data = { name, email, message };
    if (isValid) {
      axios.post("/contact/contactUs", data)
        .then((res) => {
          if (res.data.errorCode) {
            notify(messages.ToastError, messages.contactError);
          } else {
            notify(messages.ToastSuccess, messages.contactSuccess);
          }
        })
        .catch((error) => {
          console.log("error")
        });
    }
  };
  const validator = () => {
    let invalid = {
      name: false,
      email: false,
      message: false
    };
    var flag = false;
    /*Validations*/
    if (!isMatches(name, messages.OnlyAlphaNumeric)) {
      flag = invalid.name = true;
      focusById('name');
      notify(messages.ToastError, messages.InvalidName)
    } else if (!isEmail(email)) {
      flag = invalid.email = true;
      focusById('email');
      notify(messages.ToastError, messages.InvalidEmail);
    } else if (!isMatches(message, messages.RegexAlphaNumericSpecialChar)) {
      flag = invalid.message = true;
      focusById('message');
      notify(messages.ToastError, messages.invalidMessage);
    }
    setInvalid(invalid)
    return flag ? false : true;
    //return;
  }
  return (
    <Row className="main-root">
      <Col lg={7} className="p-0">
        <div className="main-left-side">
          <img src={Gadgets} alt="gadgets" />
        </div>
      </Col>
      <Col lg={5} className="p-0">
        <div className="right-wrapper">
          <div className="right-wrapper-content contact-wrapper">
            <Row className="logo m-auto mt-4">
              <img src={Logo} alt="logo" />
            </Row>
            <form className="mx-5" onSubmit={submitHandler}>
              <div className="setHeading"><h2>Contact Us</h2></div>
              <Form.Group>
                <input
                  className={"input-basic" + (invalid.name ? " invalid" : "")}
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={setName}
                  id="name"
                  maxLength={255}
                />
              </Form.Group>

              <Form.Group>
                <input
                  className={"input-basic" + (invalid.email ? " invalid" : "")}
                  placeholder={messages.EMAIL}
                  value={email}
                  onChange={setEmail}
                  id="email"
                  maxLength={255}
                />
              </Form.Group>
              <Form.Group>
                <textarea
                  className={"input-basic" + (invalid.message ? " invalid" : "")}
                  type="text"
                  rows="3"
                  placeholder={messages.HOW_MAY_I_HELP_YOU}
                  value={message}
                  onChange={setMessage}
                  id="message"
                  maxLength={10000}
                />
              </Form.Group>
              <div className="w-100 d-flex justify-content-end">
                <button
                  className="justify-content-left submit-single-btn"
                  variant="primary"
                >
                  {messages.SUBMIT} <i className="fas fa-sign-out-alt ml-1"></i>
                </button>
              </div>
            </form>
            <div href="#" className="colorClass2 p-5 pt-0 text-center">
              <span className="color-class-black font-bold">
                Already have an account?
              </span>
              <Link to="/" className="color-class"> Login</Link>
            </div>
          </div></div>
      </Col>
    </Row>
  );
};
export default ContactUs;
