import React, { useState, useEffect } from "react";
import { messages } from "../utils/constants";
import { Container } from "react-bootstrap";
import { ReactTitle } from "react-meta-tags";
import GridFilter from "../grid/grid-filter";
import ReactGrid from "../grid/reactGrid";
import ShowEntries from "../utils/show-entries";
import Pagination from "react-js-pagination";
import { notify } from '../utils/utility'
import Spinner from "../spinner/spinner";
import ActionConfirmationPopup from "../shared/action-confirmation-popup";
import AddUpdateStudent from "./popup/add-update-student.jsx";
import axios from "axios";

const DefaultStudentNamesList = () => {
    const [searchContent, setSearchContent] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(
        messages.GridRowCountOptions[0]
    );
    const [showTotalCount, setShowTotalCount] = useState(true);

    const [rowData, setRowData] = useState([]);
    const [totalCount, setTotalCount] = useState(50);
    const [skip, setSkip] = useState(0);
    const [show, setShow] = useState(false);
    const [studentInfo, setStudentInfo] = useState({});
    const [spinner, setSpinner] = useState(false);
    const [studentModelShow, setStudentModelShow] = useState(false);
    const [studentModelInfo, setStudentModelInfo] = useState({});

    function GridActions(props) {
        const status = () => {
            setOpen(props.data)
        }
        const edit = (e) => {
            props.setShow(true);
            let { id, name } = props.data
            setStudentModelInfo({ id, name });
        }
        return (
            <ul className="trigger-div mb-0 px-0">
                <i className="mx-4 fas fa-pencil-alt color-orange pointer" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" onClick={edit}></i>
                <i className="mx-4 fas fa-trash color-orange pointer" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" onClick={status}></i>
            </ul>
        );
    }

    const setOpen = (data) => {
        handleShow();
        setStudentInfo(data);
    };
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };
    const deleteFunction = (id) => {
        const data = { studentId: id }
        axios.post("/defaultStudentNames/delete", data).then((res) => {
            setSpinner(true);
            if (!res.data.errorCode) {
                setSpinner(false);
                notify(messages.ToastSuccess, messages.defaultStudentNameDeleteSuccessfully);
                getDefaultStudentNamesList()
                handleClose()
            }
            else {
                setSpinner(false);
                notify(messages.ToastError, messages.somethingWentWrong)
                handleClose()
            }
        })
    }

    useEffect(() => {
        getDefaultStudentNamesList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skip, itemsCountPerPage])


    const getDefaultStudentNamesList = () => {
        const url = "/defaultStudentNames/getList";
        setSpinner(true);
        axios.post(url, {
            skip: skip,
            limit: itemsCountPerPage.value,
            searchContent: searchContent,
            fields: ["id", "name", "status", "organizationId", "createdBy"],
            showTotalCount: showTotalCount
        }).then((res) => {
            if (res.data) {
                setSpinner(false);
                let rows = [];
                let data = res.data.items;
                for (let i in data) {
                    let item = {};
                    item.name = data[i].name;
                    item.id = data[i].id;
                    item.status = data[i].status;
                    item.organizationId = data[i].organizationId;
                    item.createdBy = data[i].CreatedBy.name || "";
                    rows.push(item);
                }
                setRowData(rows);
            }
            setTotalCount(res.data.totalCount)
        }).catch((error) => {
            console.log(error);
            setSpinner(false);
        })
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setSkip((pageNumber * itemsCountPerPage.value) - itemsCountPerPage.value)
    };

    const columnDefs = [{ field: 'name', headerName: "Name", headerClass: 'ag-custom-header', minWidth: 180 },
    { field: 'createdBy', headerName: "Created By", headerClass: 'ag-custom-header', minWidth: 180 },
    {
        field: 'action',
        cellRendererFramework: GridActions,
        cellRendererParams: {
            show: studentModelShow,
            setShow: setStudentModelShow
        },
        pinned: "right",
        headerClass: 'ag-custom-header',
        headerName: "Actions",
        maxWidth: 200
    }
    ];

    const containerStyle = {
        boxSizing: "border-box",
        height: rowData.length * messages.GridRowheight + messages.GridHeaderheight,
        minHeight: 150,
        width: "calc(100%)",
    };

    const addBtnClick = () =>{
        setStudentModelInfo({});
        setStudentModelShow(true);
    }

    return (
        <Container fluid className="px-0 body-component">
            <ReactTitle title="Home-Default-Students-Names" />
            <ActionConfirmationPopup
                handleClose={handleClose}
                handleShow={handleShow}
                setShow={setShow}
                show={show}
                message={(show && studentInfo.status === 1 && messages.deleteSensorMessage + ` "${studentInfo.name}"  student?`)}
                action={show && studentInfo.status === 1 && messages.delete}
                deactivateFunction={() => deleteFunction(studentInfo.id)}
            />
            {studentModelShow ?
                <AddUpdateStudent
                    show={studentModelShow}
                    setShow={setStudentModelShow}
                    getStudent={getDefaultStudentNamesList}
                    studentDetails={studentModelInfo}
                /> : ""}
            <div className="container-fluid mt-3">
                <div className="row">
                    <p className="font-bold mb-1">{messages.defaultStudentName}</p>
                </div>
                <hr />
                <div className="row my-5">
                    <GridFilter
                        entries={true}
                        search={true}
                        addBtnPopup={true}
                        ajaxCall={getDefaultStudentNamesList}
                        itemsCountPerPage={itemsCountPerPage}
                        //instructor={instructor}
                        setItemsCountPerPage={(e) => {
                            setSpinner(true);
                            setSkip(0);
                            setItemsCountPerPage(e);
                            setSpinner(false);
                        }}
                        addBtnPopupClick={addBtnClick}
                        restore={false}
                        setSearchContent={(e) => {
                            setSpinner(true);
                            setSkip(0);
                            setSearchContent(e);
                            setSpinner(false);
                        }}
                        tooltipTitle={"Default Student Names"}
                        setSkip={setSkip}
                        setActivePage={setActivePage}
                    />
                </div>
                <div className="container-fluid grid-list-div mb-3 ">
                    <div className=" default-students-names-table">
                        <div className="ag-theme-alpine application-grid">
                            {spinner ? (<Spinner />) : (<ReactGrid
                                id="metersGrid"
                                gridId="meters-List"
                                containerStyle={containerStyle}
                                columnDefs={columnDefs}
                                rowData={rowData}
                            />)}
                        </div>
                        {totalCount > 10 ? <div className="row mb-5">
                            <div className="col-sm-6 font-bold mt-2">
                                <ShowEntries
                                    totalCount={totalCount}
                                    itemsCountPerPage={itemsCountPerPage.value}
                                    activePage={activePage}
                                />
                            </div>
                            <div className="col-md-6 pagination-component">
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={itemsCountPerPage.value}
                                    totalItemsCount={totalCount}
                                    pageRangeDisplayed={2}
                                    onChange={handlePageChange}
                                    itemClass="pagination-item"
                                    linkClass="pagination-link"
                                    prevPageText="Previous"
                                    nextPageText="Next"
                                />
                            </div>
                        </div> : ''}
                    </div>
                </div>
            </div>
        </Container>
    )
}
export default DefaultStudentNamesList;