import React, { useState, useEffect } from 'react'
import { messages } from "../utils/constants";
import { Container } from "react-bootstrap";
import Spinner from "../spinner/spinner";
import ReactGrid from "../grid/reactGrid";
import ShowEntries from "../utils/show-entries";
import Pagination from "react-js-pagination";
import axios from "axios";
import { ReactTitle } from "react-meta-tags";


const DeviceList = () => {
    const role = localStorage.getItem("role");
    const [spinner, setSpinner] = useState(false);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(
        messages.GridRowCountOptions[0]
    );
    const [searchContent, setSearchContent] = useState("");

    const [type, setType] = useState();
    const [totalCount, setTotalCount] = useState();
    const [activePage, setActivePage] = useState(1);
    const [skip, setSkip] = useState(0);


    useEffect(() => {
        getDevicesList();
    }, [skip, itemsCountPerPage, type])

    // function GridActionsElement(props) {
    //     return (
    //         <div>
    //             {props.data.name}
    //         </div>
    //     );
    // }

    const [rowData, setRowData] = useState([]);
    // const [rowData, setRowData] = useState([
    //     { type: "mobile", macId: "321-344-333-223-ab" },
    //     { type: "tablet", macId: "321-344-332-226-ac" },
    //     { type: "mobile", macId: "321-345-323-253-ad" }

    // ]);

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "Type",
            field: "type",
            headerClass: "ag-custom-header",
            maxWidth: 800,
            minWidth: 120,
        },
        {
            headerName: "MAC - ID",
            field: "macId",
            headerClass: "ag-custom-header",
            minWidth: 150,
        }
        // {
        //     headerName: "Actions",
        //     field: "Actions",
        //     headerClass: "ag-custom-header",
        //     cellRendererFramework: GridActions,
        //     pinned: "right",
        //     maxWidth: 150,
        //     minWidth: 100,
        // },
    ]);
    const containerStyle = {
        boxSizing: "border-box",
        height: rowData.length * messages.GridRowheight + messages.GridHeaderheight,
        minHeight: 150,
        width: "calc(100%)",
    };
    const getDevicesList = () => {
        const url = "/Organizations/getDevicesList";
        setSpinner(true);
        axios.post(url, {
            type: type,
            searchContent: searchContent,
        }).then((res) => {
            setSpinner(false);
            if (res.data) {
                let rows = [];
                let data = res.data;

                for (let i in data) {
                    let deviceData = {};
                    deviceData.type = data[i].type === 1 ? "Tablet" : data[i].type === 2 ? "Mobile" : "";
                    deviceData.macId = data[i].macId;
                    deviceData.createdBy = data[i].createdBy;
                    rows.push(deviceData);
                }
                setRowData(rows);
            }
            setTotalCount(res.data.totalCount)
        }).catch((error) => {
            console.log(error);
            setSpinner(false);
        })
    }
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setSkip(pageNumber * itemsCountPerPage.value - itemsCountPerPage.value);
    };
    return (
        <Container fluid className="px-0 body-component">
            <ReactTitle title="Home-Devices" />
            {/* <ActionConfirmationPopup
                handleClose={handleClose}
                handleShow={handleShow}
                setShow={setShow}
                show={show}
                message={(show && sensorStatus === 1 && messages.deleteSensorMessage + " " + `"${sensorName}"` + " sensor?")}
                action={show && sensorStatus === 1 && messages.delete}
                deactivateFunction={() => deleteFunction(sensorId)}
            /> */}
            <div className="container-fluid mt-3">
                <div className="row">
                    <p className="font-bold mb-1"> {messages.Devices}</p>
                </div>
                <hr />

                <div className="container-fluid grid-list-div mb-3 ">
                    <div className=" organization-table">
                        <div className="ag-theme-alpine application-grid">
                            {spinner ? (<Spinner />) :
                                (<ReactGrid
                                    id="requestConsultGrid"
                                    gridId="Request-consult-List"
                                    containerStyle={containerStyle}
                                    columnDefs={columnDefs}
                                    rowData={rowData}
                                />)}
                        </div>
                        {totalCount > 10 ? (
                            <div className="row mt-4 mb-5">
                                <div className="col-sm-6 font-bold mt-2">
                                    <ShowEntries
                                        totalCount={totalCount}
                                        itemsCountPerPage={itemsCountPerPage.value}
                                        activePage={activePage}
                                    />
                                </div>
                                <div className="col-md-6 pagination-component">
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={itemsCountPerPage.value}
                                        totalItemsCount={totalCount}
                                        pageRangeDisplayed={2}
                                        onChange={handlePageChange}
                                        itemClass="pagination-item"
                                        linkClass="pagination-link"
                                        prevPageText="Previous"
                                        nextPageText="Next"
                                    />
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default DeviceList
