import React, { useState, useEffect, useRef } from "react";
import "../../style/style.css";
import { useHistory } from "react-router-dom";
import { notify, isMatches, focusById } from "../utils/utility";
import { messages } from "../utils/constants";
import SensorForm from "./component/sensor-form.jsx";

import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { ReactTitle } from "react-meta-tags";
import ActionConfirmationPopup from "../shared/action-confirmation-popup";

const AddSensor = () => {
  const history = useHistory();
  const [sensorName, setSensorName] = useState("");
  const [editSensorData, setEditSensorData] = useState("");
  const [sensorIsDisabled, setSensorIsDisabled] = useState(true);
  const [sensorAddDisabled, setSensorAddDisabled] = useState(false);
  const [editedForm, setEditedForm] = useState(false);
  const [show, setShow] = useState(false);
  const [popupValue, setPopupValue] = useState();
  const organizationId = localStorage.getItem("organizationId");
  const role = localStorage.getItem("role");
  const invalidData = {
    sensorName: false,
  };
  const [invalid, setInvalid] = useState(invalidData);
  const [action, setAction] = useState("add");
  const [isDefault, setIsDefault] = useState(false);
  const [isExist, setIsExist] = useState(false);
  const sensorFormRef = useRef();
  const editSensorId = localStorage.getItem("editSensorId") || "";
  const copyAndEditSensorId = localStorage.getItem("copyAndEditSensorId") || "";

  useEffect(() => {
    if (editSensorId) {
      setAction("edit");
    }
    if (copyAndEditSensorId) {
      setAction("copy");
    }
  }, []);
  useEffect(() => {
    if (action !== "add") {
      setSensorAddDisabled(true)
      getSensorDetails();
    }
  }, [action]);
  const getSensorDetails = () => {
    axios
      .post("/Sensors/getSensorDetail", {
        id: editSensorId || copyAndEditSensorId,
      })
      .then(function (response) {
        // handle success
        let data = response.data ? response.data : {};
        // console.log(data)
        if (!data.errorCode) {
          setSensorName(
            data.name
              ? action === "copy"
                ? data.name + " copy"
                : data.name
              : ""
          );
          setEditSensorData(data);
          if (((parseInt(role) === 2 && data.organizationId !== organizationId) || (parseInt(role) === 3 && data.isDefault)) && action === "edit") {
            setIsDefault(true)
          }
        } else {
          notify(messages.ToastError, messages.ServerNotResponding);
        }
      })
      .catch(function (error) { });
  };
  const validator = () => {
    let invalid = {
      sensorName: false,
    };
    var flag = false;
    /*Validations*/
    if (!isMatches(sensorName, messages.RegexAlphaNumericSpecialChar)) {
      flag = invalid.sensorName = true;
      focusById("sensorName");
      notify(messages.ToastError, messages.InvalidSensorName);
    }
    setInvalid(invalid);
    return flag ? false : true;
  };
  function submitHandler(redirect) {
    setEditedForm(false);
    setSensorAddDisabled(true)
    localStorage.removeItem("cancelFormChanges");
    let isValid = sensorFormRef.current.validData();
    if (isValid) {
      let sensorData = sensorFormRef.current.getValidatedData();
      if (sensorData) {
        if (action === "add") {
          sensorData.sensorName = sensorName ? sensorName.trim() : "";
          axios
            .post("/Sensors/addSensor", sensorData)
            .then(function (response) {
              if (response?.data?.id) {
                localStorage.setItem("editSensorId", response.data.id);
                setSensorAddDisabled(true);
                setAction("edit");
              }
              let data = response.data ? response.data : {};
              if (data.errorCode) {
                if (data.errorCode === 406) {
                  notify(messages.ToastError, messages.SensorAlreadyExist);
                } else {
                  notify(messages.ToastError, messages.ServerNotResponding);
                }
              } else {
                notify(messages.ToastSuccess, messages.SensorAdded);
                if (redirect) history.push("/Home/Sensor");
              }
            })
            .catch(function (error) {
              console.log("error");

              // history.push("/Home/Sensor");
            });
        }
        if (action === "edit") {
          sensorData.sensorName = sensorName ? sensorName.trim() : "";
          axios
            .post("/Sensors/updateSensor", sensorData)
            .then(function (response) {
              // handle success
              if (response?.data) {
                setSensorAddDisabled(true);
              }
              let data = response.data ? response.data : {};
              if (data.errorCode) {
                if (data.errorCode === 406) {
                  notify(messages.ToastError, messages.SensorAlreadyExist);
                } else {
                  notify(messages.ToastError, messages.ServerNotResponding);
                }
              } else if (data) {
                notify(messages.ToastSuccess, messages.SensorUpdated);
                if (redirect) history.push("/Home/Sensor");
              }
            })
            .catch(function (error) {
              console.log(error);
              // history.push("/Home/Sensor");
            });
        }
        if (action === "copy") {
          sensorData.sensorName = sensorName ? sensorName.trim() : "";
          axios
            .post("/Sensors/addSensor", sensorData)
            .then(function (response) {
              let data = response.data ? response.data : {};
              if (data.errorCode) {
                if (data.errorCode === 406) {
                  notify(messages.ToastError, messages.SensorAlreadyExist);
                } else {
                  notify(messages.ToastError, messages.ServerNotResponding);
                }
              } else {
                notify(messages.ToastSuccess, messages.SensorAdded);
                if (redirect) history.push("/Home/Sensor");
              }
            })
            .catch(function (error) {
              console.log(error);
              // history.push("/Home/Sensor");
            });
        }
        localStorage.removeItem("editSensorId");
        localStorage.removeItem("copyAndEditSensorId");
      }
    }
  }

  const onBlurHandler = (name) => {
    axios
      .post("/Sensors/sensorExist", { name , action})
      .then(function (response) {
        setIsExist(response?.data?.exist)
      }).catch(function (error) {
        console.log("error");
      })
  }

  const enableDisableSave = (_enable) => {
    setSensorIsDisabled(_enable);
  };
  let disable = !sensorName || sensorIsDisabled;

  const cancelFuction = () => {
    if (editedForm) {
      setShow(true);
      setPopupValue({
        callBackFunction: () => {
          localStorage.removeItem("editSensorId");
          localStorage.removeItem("copyAndEditSensorId");
          localStorage.removeItem("cancelFormChanges");
          history.push("/Home/Sensor");
        },
        headerName: "Alert",
        message: messages.cancelFormChanges,
        action: "Yes",
        cancelBtn: "No",
      });
    } else {
      localStorage.removeItem("editSensorId");
      localStorage.removeItem("copyAndEditSensorId");
      history.push("/Home/Sensor");
    }
  };
  useEffect(() => {
    localStorage.setItem("cancelFormChanges", editedForm);
  }, [editedForm]);
  return (
    <Container>
      <ReactTitle
        title={action === "add" ? "Home-Add-Sensor" : "Home-Edit-Sensor"}
      />
      <Row className="row border-bottom">
        <div className="add-update-heading">
          {action === "add" ? messages.AddSensor : action === "edit"
            ? messages.EditSensor
            : messages.CopySensor}
        </div>
      </Row>
      <ActionConfirmationPopup
        handleClose={() => setShow(false)}
        handleShow={() => setShow(true)}
        setShow={setShow}
        show={show}
        message={show ? popupValue.message : ""}
        action={show ? popupValue.action : ""}
        deactivateFunction={() => popupValue.callBackFunction()}
        headerName={show ? popupValue.headerName : ""}
        cancelBtn={show ? popupValue.cancelBtn : ""}
      />
      <Row className="add-update-right-wrapper">
        <Col lg={6} className="add-sensor-left">
          <input
            className="input-basic  mt-4"
            value={sensorName}
            type="text"
            id="sensorName"
            placeholder="Enter sensor name*"
            onChange={(e) => {
              setEditedForm(true);
              setSensorName(e.target.value);
              if (sensorAddDisabled) {
                setSensorAddDisabled(false);
              }
            }}
            onBlur={() => sensorName && onBlurHandler(sensorName)}
            disabled={isDefault}
            maxLength={250}
          // disabled={spinner && true}
          />
          <br />
          {isExist && <span style={{ color: "red", fontWeight: 700 }}>Sensor Name is already exist.</span>}
        </Col>
        <Col lg={6}>
          <SensorForm
            ref={sensorFormRef}
            onSensorSave={submitHandler}
            editSensorId={editSensorId}
            editSensorData={editSensorData}
            enableDisableCallback={enableDisableSave}
            showCalculated={false}
            action={isDefault ? "view" : action}
            setEditedForm={() => {
              if (sensorAddDisabled) {
                setSensorAddDisabled(false);
              }
              setEditedForm(true);
            }}
          />
        </Col>
      </Row>
      <Row className="px-2">
        <Col lg={12} className="save-cancel-exit-btn">
          <button className="saveBtn m-1" onClick={cancelFuction}>
            Cancel
          </button>
          <button
            className="saveBtn m-1 submitSaveBtn"
            onClick={() => submitHandler(false)}
            disabled={disable || sensorAddDisabled}
          >
            Save
          </button>
          <button
            onClick={() => submitHandler(true)}
            disabled={disable || sensorAddDisabled}
            className="saveBtn m-1 submitSaveBtn"
          >
            Save &amp; Exit
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default AddSensor;
